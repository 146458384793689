import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Loading from "../../Commons/Loading";
import NodataFound from "../../Commons/NoDataFound";
import Pagination from "../../Commons/Table/Pagination";

export default function MomentsVsDaysGraph(props) {
    const { loader, analysis } = props;
    const PageSize = 12;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [data, setData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [canNextPage, setCanNextPage] = useState(true);
    const [canPreviousPage, setCanPreviousPage] = useState(false);
    const [chartType, setChartType] = useState("line");
    const [sortedBarData, setSortedBarData] = useState([]);
    const [sortedLineData, setSortedLineData] = useState([]);

    useEffect(() => {
        if (props?.data?.length > 0) { 
            const detectDateFormat = (dates) => {
            // Check the first date for format
            const sampleDate = dates[0].date;
            const [part1] = sampleDate.split("/").map(Number);
            return part1 > 12 ? "dd/mm/yy" : "mm/dd/yy";
            };
          
            const parseDate = (dateStr, format) => {
            const [part1, part2, year] = dateStr.split("/").map(Number);
            if (format === "dd/mm/yy") {
                return new Date(year, part2 - 1, part1); // dd/mm/yy 
            } else {
                return new Date(year, part1 - 1, part2); // mm/dd/yy 
            }
            };
          
            const sortData = (data) => {
                const format = detectDateFormat(data); // Detect the format
                return [...data].sort((a, b) => {
                    const dateA = parseDate(a.date, format);
                    const dateB = parseDate(b.date, format);
                    return dateA - dateB;
                });
            };
          
            const sorted = sortData(props.data);
            setData(sorted);
            setPaginatedData(sorted.slice(0, PageSize));            
            setPageIndex(0);
            setCurrentPage(1);
        }
    }, [props?.data, chartType]);

    useEffect(() => {
        if(paginatedData?.length > 0){
            setSortedBarData(paginatedData);
            setSortedLineData(data)
        }
    }, [paginatedData]);

    const nextPage = () => {
        if (pageIndex < Math.ceil(data.length / PageSize) - 1) {
            let newIndex = pageIndex + 1;
            let newData = data.slice(newIndex * PageSize, (newIndex + 1) * PageSize);
            setPaginatedData(newData);
            setPageIndex(newIndex);
            setCanPreviousPage(true);
            if (newIndex >= Math.ceil(data.length / PageSize) - 1) {
                setCanNextPage(false);
            }
        }
    };

    const previousPage = () => {
        if (pageIndex > 0) {
            let newIndex = pageIndex - 1;
            let newData = data.slice(newIndex * PageSize, (newIndex + 1) * PageSize);
            setPaginatedData(newData);
            setPageIndex(newIndex);
            setCanNextPage(true);
            if (newIndex <= 0) {
                setCanPreviousPage(false);
            }
        }
    };

    const gotoPage = (val) => {
        let newIndex = val;
        let newData = data.slice(newIndex * PageSize, (newIndex + 1) * PageSize);
        setPaginatedData(newData);
        setPageIndex(newIndex);
        setCanNextPage(newIndex < Math.ceil(data.length / PageSize) - 1);
        setCanPreviousPage(newIndex > 0);
        setCurrentPage(val + 1);
    };

    const barSeries = [{
        name: 'Moment vs Days',
        data: sortedBarData.length > 0 ? sortedBarData.map(x => x.statVal) : [0]
        // data: data.map(x => x.statVal)

    }];

    const barIcon= 'bx bx-bar-chart';
    const lineIcon= 'bx bx-line-chart';

    const barOptions = {
        chart:
        {
            height: 350,
            type: 'bar',
            // toolbar: { show: false }
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [
                        {
                            icon: `<i class="${barIcon}"></i>`,
                            index: 0,
                            title: "Bar Chart",
                            class: `custom-bar-icon ${chartType=='bar'?'select':''}`,
                            click: () => setChartType("bar"),
                        },
                        {
                            icon: `<i class="${lineIcon}"></i>`,
                            index: 1,
                            title: "Line Chart",
                            class: `custom-line-icon ${chartType=='line'?'select':''}`,
                            click: () => setChartType("line"),
                        },
                    ],
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "10px",
            },
        },
        xaxis: {
            categories: sortedBarData.length > 0 ? sortedBarData.map(x => {
                const [day, month, year] = x.date.split('/');
                return `${day}/${month}/${year}`;
            }) : ["No Data"],
            title: {
                text: 'Days',
                offsetY: -10,
                align: "left",
            }
        },
        yaxis: {
            title: { text: 'Moment' }
        },
        colors: ['#0077b6'],
        fill: {
            opacity: 1,
        },
        tooltip: {
            shared: true,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const data = sortedBarData[dataPointIndex];
                return `
                    <div class="tooltip-custom"> 
                        Moment: ${analysis}<br/>
                        Moment vs Days: ${series[seriesIndex][dataPointIndex]}<br />
                        Total Calls: ${data.totalCalls} calls
                    </div>
                `;
            }
        },
    };
    const lineSeries = [{
        name: 'Moment vs Days',
        data: sortedLineData.length > 0 ? sortedLineData.map(x => x.statVal) : [0]

    }];

    const lineOptions = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [
                        {
                            icon: `<i class="${barIcon}"></i>`,
                            index: 0,
                            title: "Bar Chart",
                            class: `custom-bar-icon ${chartType=='bar'?'select':''}`,
                            click: () => setChartType("bar"),
                        },
                        {
                            icon: `<i class="${lineIcon}"></i>`,
                            index: 1,
                            title: "Line Chart",
                            class: `custom-line-icon ${chartType=='line'?'select':''}`,
                            click: () => setChartType("line"),
                        },
                    ],
                },
            },
        },
        xaxis: {
            categories: sortedLineData.length > 0 ? sortedLineData.map(x => {
                const [day, month, year] = x.date.split('/');
                return `${day}/${month}/${year}`;
            }) : ['No Data'],
            title: {
                text: 'Days',
                offsetY: -10,
                align: "left",
            },
            labels: {
                rotate: -40,
                rotateAlways: true,
                style: {
                    fontSize: "10px",
                    fontWeight: "400",
                },
            },
            formatter: function (value, timestamp, index) {
                // Show only 5 labels
                const totalLabels = data?.length || 0;
                const step = Math.floor(totalLabels / 5);  // We use 5 to get 6 labels (start, 3 in between, end)
                if (index === 0 || index === totalLabels - 1 || index % step === 0) {
                    return value;
                }
                return '';
            },
            tickAmount: 6,
        },
        yaxis: {
            title: { text: 'Moment' },
        },
        colors: ['#0077b6'],
        markers: {
            size: 4,
            colors: ['#0077b6'],
            strokeColors: '#0077b6',
            strokeWidth: 2,
            hover: { size: 7 }
        },
        stroke: { curve: 'smooth' },
        tooltip: {
            shared: true,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const data = sortedLineData[dataPointIndex];
                return `
                    <div class="tooltip-custom"> 
                        Moment: ${analysis}<br/>
                        Moment vs Days: ${series[seriesIndex][dataPointIndex]}<br />
                        Total Calls: ${data?.totalCalls} calls
                    </div>
                `;
            }
        },
    };

    return (
        <>
            {loader ?(
                <div className="loader-container">
                    <Loading variant="dark" />
                </div> ) : 
                // barSeries[0].data && barSeries[0].data.length > 0 ?
                    (<div className="p-4">
                        <ReactApexChart type={chartType} options={chartType === 'bar' ? barOptions : lineOptions} series={chartType === 'bar' ? barSeries : lineSeries} height="350" /> {
                            sortedBarData.length === 0 && sortedLineData.length === 0 ?( <div>No Data Available</div>):(
                                chartType === 'bar' && (
                                    <Pagination
                                     className="pagination-bar mb-1"
                                     currentPage={currentPage}
                                     totalCount={data.length}
                                     pageSize={PageSize}
                                     pageIndex={pageIndex}
                                     currentPages={Math.ceil(data.length / PageSize)}
                                     nextPage={nextPage}
                                     previousPage={previousPage}
                                     gotoPage={gotoPage}
                                     canPreviousPage={canPreviousPage}
                                     canNextPage={canNextPage}
                                     handlePageIndex={gotoPage}
                                    /> 
                                )
                            )
                        }
                    </div>)
                    // <NodataFound />
            }
        </>
    );
}
