import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Loading from '../Commons/Loading';
import './profile.css';
//import profileService from "../Services/profile.service";
import toast from "../Toast/toaster";
import { history } from "../../redux-container/store";
import { apiCall } from "../../Components/Services/Interceptor";
import { Popover, OverlayTrigger } from "react-bootstrap";

function ChangePassword(){
    const [loading, setLoading] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [CPError, setCPError] = useState()
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const CHANGE_PASSWORD = "/odio/api/user/reset-password";
    const [data, setData]=useState({       
      confirmPassword:'',
      newPassword:'',
      password:'',
      errors: {
        password: '',
        newPassword: '',
        confirmPassword: '',
      },
    });
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [newValues, setNewValues] = React.useState({
      newPassword: "",
      showNewPassword: false,
    });
    const [confValues, setConfValues] = React.useState({
      confPassword: "",
      showConfPassword: false,
    });
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowNewPassword = () => {
      setNewValues({ ...newValues, showNewPassword: !newValues.showNewPassword });
    };
    const handleClickShowConfPassword = () => {
      setConfValues({ ...confValues, showConfPassword: !confValues.showConfPassword });
    };
    const [errors,setErrors]=useState({});

    

    const validate = (name,value,prevData) =>{
      let formError='';
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{10,}$/

      if(name=='password'){
        if(!value){
          formError="Enter your current password."
        }
      }
       
      if(name=='newPassword'){
        if(!value){
          formError="Enter your new password."
        }
        else if (!regex.test(value)) {
          formError = "Password must be more than 10 characters"
        }
        // else if (value === data.confirmPassword) {
        //   formError = 'New Password & Confirm Password should be different.';
        // } 
        // else if (value && value.length < 10) {
        //   formError = "Password must be more than 10 characters."
        // } 
      }

      if(name=='confirmPassword'){
        if(!value){
          formError ="Renter your new password."
        }
        else if(value==data.password){
          formError ="Current Password and old Password should be different"
        }
        else if(value!=prevData.newPassword){
          formError ="New Password & Confirm Password should be same."
        }
      }
      return formError;
    }

    const changePassword = async (data) => {
      await apiCall.put(CHANGE_PASSWORD, data).then((response) => {
        if (response) {
          if (response.data.status === 0) {
            toast.success(response.data.message);
            setData(prevData => ({...prevData,
              confirmPassword: '',
              newPassword: '',
              password: '',
              errors:{
                password: '',
                newPassword: '',
                confirmPassword: ''
              }
            })
            );
            setFormLoader(false)
          }
        }
      })
      .catch((error) => {
        setFormLoader(false)
        if (error.response.data.status === 400) {
          setCPError(error.response.data.message);
          //toast.error(error.response.data.message);
          
          return error.response;
        } else if (error.response.data.status === 403) {
          toast.error(error.response.data.message);
          history.push("/login");
        } else if (error.response.data.status === 404) {
          toast.error(error.response.data.message);
        } else if (error.response.data.status === 500) {
          toast.error(error.response.data.message);
        } 
      });
    };


    const handleFormSubmit = (e) => {
      setLoading(true);
      e.preventDefault();
      if(formLoader){
        return;
      }
      else{
        setFormLoader(true)
        if(data?.errors?.confirmPassword==''&&data?.errors?.newPassword==''&&data?.errors?.password==''){
          changePassword({confirmPassword:data?.confirmPassword,newPassword:data?.newPassword,password:data?.password})
        }
        // else
        // {
        //   setLoading(false);
        //   setFormLoader(false)
        // }
      }
      
      //  validate(data);
      //  setErrors(formError);
      
      
    }


    const handleChange =(event) =>{
      const { name, value } = event.target;
      // setData(prevData => ({ ...prevData, [name]: value ,errors:{...prevData.errors,[name]: validate(name,value)}}));
      
      setData((prevData) => ({ ...prevData, [name]: value }));

      setData((prevData) => {
        let errors = { ...prevData.errors };

        if (name === 'newPassword') {
          errors.newPassword = validate('newPassword', prevData.newPassword,prevData);
          errors.confirmPassword = validate('confirmPassword', prevData.confirmPassword,prevData);
        } 
        else {
          errors[name] = validate(name, value, prevData);
        }
        return { ...prevData, errors };
      })
    }


    function handleFocusOldPass(){
        errors.password="";
    }
    function handleFocusNewPass(){
        errors.newPassword="";
    }
    function handleFocusCNewPass(){
        errors.confirmPassword="";
    } 

    const passwordFormat = `Your password must be atleast 10 characters long containing atleast one uppercase letter,
    one lowercase letter, one special character and one digit`

    const popoverr =()=> (
      <Popover id="popover-trigger-hover-focus" title="right">
        <h3 className="password-popover-header" style={{fontSize:"14px",textAlign:"center",marginTop:"1px"}}>
          {passwordFormat}
          {/* {passwordFormat.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))} */}
        </h3>
      </Popover>
    )

    

return(
    <>
        <div id="changpswrdlink" className="mt-3">
            <div className="center-pawrd-box">
                <div className="card-title d-flex align-items-center">
                    <i className="bx bxs-lock createpswrdIcon "></i>
                    <h5 className="mb-0 edit-profile-header w-100">Change Password</h5>
                </div>
                <hr />
                <form className="row g-3" >
                  <div className="col-md-12 password-content">
                    <div className="">
                      <label className="form-label">Current Password</label>
                      <div className="input-group"> <span className="input-group-text bg-transparent"><i className="bx bxs-lock-open"></i></span>
                        <input className="form-control border-end-0" 
                          id="inputChoosePassword" 
                          placeholder="Current Password"
                          name="password" 
                          value={data.password} 
                          onFocus={handleFocusOldPass} 
                          onChange={handleChange} 
                          type={values.showPassword ? "text" : "password"}  
                        />
                        <a onClick={handleClickShowPassword} tabindex="-1" href="javascript:;" className="input-group-text bg-transparent">{values.showPassword ? <i className="bx bx-show"></i>: <i className="bx bx-hide"></i>}</a>
                      </div>
                      <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>{data.errors.password? <span  style={{color:'red'}}>{data.errors.password}</span>:<span  style={{color:'red'}}>{CPError}</span> }</p> 
                    </div>
                        <div style={{ position: "relative" }}>
                          <label className="form-label">New Password</label>
                          <div className="input-group">
                            <span className="input-group-text bg-transparent">
                              <i className="bx bxs-lock-open"></i>
                            </span>
                            <input
                              className="form-control border-end-0"
                              id="inputNewPassword"
                              placeholder="New Password"
                              name="newPassword"
                              value={data.newPassword}
                              onChange={handleChange}
                              type={newValues.showNewPassword ? "text" : "password"}
                            />
                            <a
                              onClick={handleClickShowNewPassword}
                              tabIndex="-1"
                              href="javascript:;"
                              className="input-group-text bg-transparent"
                            >
                              {newValues.showNewPassword ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
                            </a>
                              <button
                                className="mb-0 ms-0"
                                style={{ border: "none", backgroundColor: "white", marginTop: "1px" }}
                                onClick={(e) => e.preventDefault()}
                              ></button>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              transform: "translateY(-50%)",
                              right: "-40px",
                            }}
                          >
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="bottom"
                              overlay={popoverr()}
                              rootClose
                            >
                            <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
                            </OverlayTrigger>
                          </div>

                          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
                            {data?.errors?.newPassword && <span style={{ color: "red" }}>{data?.errors?.newPassword}</span>}
                          </p>
                        </div>


                    <div className="">
                      <label className="form-label"> Confirm Password</label>
                      <div className="input-group"> <span className="input-group-text bg-transparent"><i className="bx bxs-lock"></i></span>
                        <input className="form-control border-end-0" 
                          id="inputconfirmPassword" 
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={data.confirmPassword}
                          // onFocus={handleFocusCNewPass}
                          onChange={handleChange}
                          type={confValues.showConfPassword ? "text" : "password"}  
                        />
                        <a onClick={handleClickShowConfPassword} tabindex="-1" href="javascript:;" className="input-group-text bg-transparent">{confValues.showConfPassword? <i className="bx bx-show"></i>: <i className="bx bx-hide"></i>}</a>
                        {/* <IconButton
                        onClick={handleClickShowConfPassword}
                        onMouseDown={handleMouseDownPassword}
                        >
                        {confValues.showConfPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton> */}
                      </div>
                      <p className="my-1 mb-0 lh-1 font-12" style={{height:'18px'}}>{data?.errors?.confirmPassword && <span style={{color:'red'}}>{data?.errors?.confirmPassword}</span> }</p> 
                    </div>
                  </div>
                  <div className="col-12 text-sm-end mt-2 cancelUpadte-btn">
                    <button type="submit" className="btn px-1 lh-base update-btn" disabled={data?.errors?.confirmPassword==''&&data?.errors?.newPassword==''&&data?.errors?.password==''?false:true} onClick={handleFormSubmit} id="changepswrd">{ formLoader ?<Loading variant="light"/> : 'Update'}</button>
                  </div>
                </form>
            </div>
        </div>
    </>
  )
}

export default ChangePassword;