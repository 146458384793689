import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../Commons/Loading";
import background from '../../assets/images/login-images/sign-in.png';
import odio_logo_blue from "../../assets/images/odio_logo_blue.png";
import logo from "../../assets/images/logoIcon.png";
import toast from "../Toast/toaster";
import { history } from "../../redux-container/store";
import { apiCall } from "../Services/Interceptor";
import { Popover, OverlayTrigger } from "react-bootstrap";
import userService from "../Services/user.service";
import './login.css';

export default function ForgotPasswordEnterOTP({ email }) {
  const [loading, setLoading] = useState(false);
  const [CPError, setCPError] = useState();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [timer, setTimer] = useState(120);

  const CHANGE_PASSWORD = "/odio/api/user/validate-code";
  const [data, setData] = useState({
    confirmPassword: "",
    newPassword: "",
    code: "",

    errors: {
      newPassword: "",
      confirmPassword: "",
    },
  });
  // const [values, setValues] = React.useState({
  //   password: "",
  //   showPassword: false,
  // });
  const [newValues, setNewValues] = React.useState({
    newPassword: "",
    showNewPassword: false,
  });
  const [confValues, setConfValues] = React.useState({
    confPassword: "",
    showConfPassword: false,
  });

  const handleClickShowNewPassword = () => {
    setNewValues({ ...newValues, showNewPassword: !newValues.showNewPassword });
  };
  const handleClickShowConfPassword = () => {
    setConfValues({
      ...confValues,
      showConfPassword: !confValues.showConfPassword,
    });
  };
  const [errors, setErrorsValidate] = useState({});

  const validate = (name, value, prevData) => {
    let formError = "";
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).*$/;

    if (name == "newPassword") {
      if (!value) {
        formError = "Enter your new password.";
      } else if (!regex.test(value)) {
        formError = "Try a mix of letters, numbers, and symbols.";
      } else if (value.length<=10) {
        formError = "Use 10 characters or more for your password";
      } else {
        formError = "";
      }
    }

    if (name == "confirmPassword") {
      if (!value) {
        formError = "Renter your new password.";
      } else if (value !== prevData.newPassword) {
        formError = "Those passwords didn’t match. Try again.";
      } else{
        formError = "";
      }
    }
    return formError;
  };

  const changePassword = async (data) => {
    return await apiCall
      .put(CHANGE_PASSWORD, data)
      .then((response) => {
        if (response) {
          if (response?.data?.status === 0) {
            response?.data?.message && toast?.success(response.data.message);
            history.push("/login");
          } else if (response?.data?.status === 1) {
            toast.error(response.data.message)
          }
        }
      })
    // .catch((error) => {
    //   if (error?.response?.data?.status) {
    //     if (error?.response?.data?.status === 400) {
    //       setCPError(error.response.data.message);
    //       toast.error(error.response.data.message);
    //       return error.response;
    //     } else if (error.response.data.status === 403) {
    //       toast.error(error.response.data.message);
    //       history.push("/login");
    //     } else if (error.response.data.status === 404) {
    //       toast.error(error.response.data.message);
    //     } else if (error.response.data.status === 500) {
    //       toast.error(error.response.data.message);
    //     }
    //   }
    // });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    //  validate(data);
    //  setErrors(formError);
    if (
      data?.errors?.confirmPassword == "" &&
      data?.errors?.newPassword == ""
    ) {
      changePassword({
        confirmPassword: data?.confirmPassword,
        newPassword: data?.newPassword,
        email,
        code: data?.code
      }).then((response) => {
        setLoading(false);
        if (response) {
          setData({
            confirmPassword: "",
            newPassword: "",
            code: "",
            errors: {
              newPassword: "",
              confirmPassword: "",
            },
          });
        } else {
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setData((prevData) => {
      let errors = {...prevData.errors};
      
      if (name === 'newPassword') {
        errors.newPassword = validate('newPassword', value, prevData);
      }
      if (name === 'confirmPassword') {
        errors.confirmPassword = validate('confirmPassword', value, prevData);
      }
      return { ...prevData, [name]: value, errors };
    })
  };

  // function handleFocusOldPass() {
  //   errors.password = "";
  // }
  function handleFocusNewPass() {
    errors.newPassword = "";
  }
  function handleFocusCNewPass() {
    errors.confirmPassword = "";
  }

  const passwordFormat = `Your password must be atleast 10 characters long containing atleast one uppercase letter,
    one lowercase letter, one special character and one digit`;

  const popoverr = () => (
    <Popover id="popover-trigger-hover-focus" title="right">
      <h3
        className="password-popover-header"
        style={{ fontSize: "14px", textAlign: "center", marginTop: "1px" }}
      >
        {passwordFormat}
        {/* {passwordFormat.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))} */}
      </h3>
    </Popover>
  );

  const handleResend = () => {
    userService.forgotPassword({ email }).then((response) => {
      if (response.data.status === 0) {
        setIsResend(false);
        setTimer(120);
      }
    });
  }

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          setIsResend(true);
          countdown && clearInterval(countdown)
        }
        return prevTimer - 1
      });
    }, 1000);
    return () => countdown && clearInterval(countdown);
  }, [timer === 120]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };



  return (
    <div className="login-container">
      <div className="welcome-section">
        <div className="logo-container">
          <img src={logo} alt="log" className="logo" />
          <img src={odio_logo_blue} alt="Odio Logo" className="logo" />
        </div>
        <div className="welcome-text">
          <p className='welcome-message'>Nice to see you again</p>
          <h1 className='welcome-back'>WELCOME BACK</h1>
          <div className="slash"></div>
          <p className='welcome-tagline'>Transform Customer Interactions with our AI-Driven Conversational Intelligence.</p>
        </div>
        <div className="additional-links" style={{ position: "absolute", bottom: "20px", left: "20px" }}>
          {/* <div className='link-1'> */}
          <a href="https://www.odioiq.com" target="_blank" style={{ color: 'white' }}>Odio</a>
          <a href="https://www.odioiq.com/blog/" target="_blank" style={{ color: 'white' }}>Blogs</a>
          <a href="https://www.odioiq.com/privacy-policy-2/" target="_blank" style={{ color: 'white' }}>Privacy Policy</a>
          {/* </div> */}
          {/* <div className='link-2'> */}
          <a href="https://www.odioiq.com/product/integrations/" target="_blank" style={{ color: 'white' }}>Integrations</a>
          <a href="https://www.odioiq.com/product/faq-2/" target="_blank" style={{ color: 'white' }}>FAQs</a>
          {/* </div> */}
        </div>
      </div>
      <div className="login-section">
        {!isResend ? (
          <form className="row g-3" onSubmit={handleSubmit} style={{ marginTop: '2rem' }}>
            <div className="col-md-12">
              <label className="form-label" >Please enter the OTP sent to your Email</label>
              <div className="input-group" style={{ width: '97%' }}>
                <input
                  name="code"
                  className="form-control form-control-sm"
                  id="inputEmail"
                  placeholder="Enter OTP"
                  value={data.code}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div style={{ margin: '.5rem .6rem', textAlign: 'right', color: '#479ef5' }}>OTP Expires In: {formatTime(timer)}</div>
              <div>
                {/* <label className="form-label"> New Password</label> */}
                <div className="input-group" style={{ width: '97%' }}>
                  {/* <span className="input-group-text bg-transparent">
                    <i className="bx bxs-lock-open"></i>
                  </span> */}
                  {/* <div className="input-group" style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                  <input
                    className="form-control form-control-sm border-end-0"
                    id="inputNewPassword"
                    placeholder="New Password"
                    name="newPassword"
                    value={data.newPassword}
                    onFocus={handleFocusNewPass}
                    onChange={handleChange}
                    type={newValues.showNewPassword ? "text" : "password"}
                  />
                  <a
                    onClick={handleClickShowNewPassword}
                    tabIndex="-1"
                    href="javascript:;"
                    className="input-group-text bg-transparent"
                  >
                    {newValues.showNewPassword ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
                  </a>
                  {/* <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverr()}
                    rootClose
                    style={{ zIndex: "1000" }}
                  >
                    <button
                      type="button"
                      className="mb-0 ms-3"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        marginTop: "1px",
                      }}
                    >
                      <i className="fadeIn animated bx bx-info-circle text-primary-blue"></i>
                    </button>
                    </OverlayTrigger> */}

                  {/* </div> */}
                </div>

                <p
                  className="my-1 mb-0 lh-1 font-12"
                  style={{ height: "18px" }}
                >
                  {data?.errors?.newPassword && (
                    <span style={{ color: "red" }}>{data?.errors?.newPassword}</span>
                  )}
                </p>
              </div>

              <div>
                {/* <label className="form-label"> Confirm Password</label> */}
                <div className="input-group" style={{ width: '97%' }}>
                  {/* <span className="input-group-text bg-transparent">
                    <i className="bx bxs-lock"></i>
                  </span> */}
                  <input
                    className="form-control form-control-sm border-end-0"
                    id="inputconfirmPassword"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={data.confirmPassword}
                    onFocus={handleFocusCNewPass}
                    onChange={handleChange}
                    type={confValues.showConfPassword ? "text" : "password"}
                  />
                  <a
                    onClick={handleClickShowConfPassword}
                    tabIndex="-1"
                    href="javascript:;"
                    className="input-group-text bg-transparent"
                  >
                    {confValues.showConfPassword ? <i className="bx bx-show"></i> : <i className="bx bx-hide"></i>}
                  </a>
                </div>
                <p
                  className="my-1 mb-0 lh-1 font-12"
                  style={{ height: "18px" }}
                >
                  {data?.errors?.confirmPassword && (
                    <span style={{ color: "red" }}>{data?.errors?.confirmPassword}</span>
                  )}
                </p>
              </div>
            </div>
            <div className="btn-container">
              <button
                type="submit"
                className="btn btn-submit"
                disabled={data.code && data.newPassword.length >= 10 && data.confirmPassword.length >= 10 ? false : true}
                style={{ background: '#479ef5', color: 'white', marginRight: '0.5rem' }}
              >
                {loading ? <Loading variant="light" /> : "Submit"}
              </button>
            </div>
            <a href="/login" className="btn btn-light btn-md" style={{ color: '#479ef5', background: 'none' }}>
                <i className="bx bx-arrow-back me-1" />
                Back to Login
            </a>
          </form>
        ) : (
          <div className="d-grid gap-2 mt-0">
            <button
              type="button"
              onClick={handleResend}
                className="btn btn-submit"
              >
                Resend OTP
              </button>
              <a href="/login" className="btn btn-light btn-md" style={{ color: '#479ef5', background: 'none', marginRight: '1rem' }}>
                <i className="bx bx-arrow-back me-1" />
                Back to Login
              </a>
            </div>
        )}
      </div>
    </div>
  );
}
