import React, { useEffect, useState } from "react";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import { apiCall } from "../../Services/Interceptor";
import {
  HYPERLINK_SVG,
  IFRAME_SVG,
  IMAGE_SVG,
  PDF_SVG,
  TEXT_SVG,
  VIDEO_SVG,
} from "../../../Constants/constant";
import "./Grapes.css";
import {
  updateDocument,
  uploadFile,
  saveToDraft,
} from "../../Services/knowledgeBase.service";
import Loading from "../Loading";
import { useSelector } from "react-redux";
import toaster from "../../Toast/toaster";
import CloseIcon from "@mui/icons-material/Close";
const Grapes = ({
  actionType,
  isDraft,
  draftDocId,
  getDocumentDetalisEditCase,
  setTabSelect,
}) => {
  const [editorInstance, setEditorInstance] = useState(null);
  const [assets, setAssets] = useState([]);
  const [isSaveSubmitDisabled, setIsSaveSubmitDisabled] = useState(false);
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState(false);
  const initialEditCaseData = useSelector(
    (state) => state.knowledgeBase.editDocData
  );
  const initialData = {
    editorComponents: initialEditCaseData?.editorComponents,
    editorCss: initialEditCaseData?.editorCss,
    editorHtml: initialEditCaseData?.editorHtml,
    editorStyles: initialEditCaseData?.editorStyles,
  };
  const uploadToAPI = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const title = file.name;
      const mimeType = file.type;
      const response = await uploadFile(formData, title, mimeType);
      if (response?.data?.status === 0) {
        const { signedUrl, url, id } = response.data?.data;
        return { signedUrl, url, id };
      } else {
        console.error("Error uploading file to API:", response.data);
        toaster.error(response.data.error);
        return null;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };
  const extractMediaComponents = (components) => {
    const mediaComponents = [];
    components.forEach((comp) => {
      if (comp.type === "image" || comp.type === "video") {
        mediaComponents.push({
          src: comp.type === "image" ? comp.attributes.src : comp.src,
          type: comp.type,
        });
      } else if (comp.type === "document") {
        mediaComponents.push({
          src: comp.src,
          type: comp.type,
        });
      }
    });
    return mediaComponents;
  };
  const getSignedUrls = async (mediaSources) => {
    const payload = mediaSources?.reduce((acc, comp, index) => {
      acc[`additionalProp${index + 1}.${comp.type}`] = comp.src;
      return acc;
    }, {});

    try {
      const response = await apiCall.post("/odio/api/folder/document/content", {
        data: payload,
      });
      return response.data?.data;
    } catch (error) {
      console.error("Error getting signed URLs:", error);
      return null;
    }
  };
  useEffect(() => {
    const editor = grapesjs.init({
      container: "#gjs",
      height: "700px",
      width: "100%",
      components: initialData.editorComponents && initialData.editorComponents,
      style: initialData.editorStyles && initialData.editorStyles,
      blockManager: {
        blocks: [
          {
            id: "text",
            label: "Text",
            category: "Basic",
            media: TEXT_SVG,
            activate: true,
            content: {
              type: "text",
              content: "Insert your text here",
              style: { padding: "10px" },
            },
          },
          {
            id: "link",
            label: "Link",
            category: "Basic",
            media: HYPERLINK_SVG,
            activate: true,
            content: {
              type: "link",
              content: "Insert your link here",
              style: { color: "#d983a6", cursor: "pointer" },
              attributes: {
                href: "#",
                target: "_blank",
                rel: "noopener noreferrer",
              },
              traits: [
                {
                  type: "text",
                  label: "URL",
                  name: "href",
                  placeholder: "https://www.example.com",
                },
                {
                  type: "checkbox",
                  label: "Open in new tab",
                  name: "target",
                  valueTrue: "_blank",
                  valueFalse: "_self",
                },
              ],
            },
          },
          {
            id: "image",
            label: "Image",
            media: IMAGE_SVG,
            content: { type: "image" },
            activate: true,
          },
          {
            id: "video",
            label: "Video",
            category: "Basic",
            media: VIDEO_SVG,
            activate: true,
            content: {
              type: "video",
              tagName: "video",
              attributes: {
                controls: true,
                autoplay: false,
                loop: false,
                muted: false,
              },
              style: {
                height: "350px",
                width: "100%",
              },
            },
          },
          {
            id: "iframe-block",
            label: "Iframe",
            category: "Basic",
            media: IFRAME_SVG,
            content: {
              type: "iframe",
              tagName: "iframe",
              attributes: {
                src: "https://www.example.com",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                referrerpolicy: "strict-origin-when-cross-origin",
              },
              style: {
                height: "350px",
                width: "100%",
              },
            },
          },
          {
            id: "document-block",
            label: "Document",
            category: "Basic",
            media: PDF_SVG,
            activate: true,
            content: {
              type: "document",
              attributes: {
                src: "",
              },
              style: {
                width: "100%",
              },
            },
          },
        ],
      },
      assetManager: {
        uploadFile: async (event) => {
          const file = event.dataTransfer
            ? event.dataTransfer.files[0]
            : event.target.files[0];
          if (file) {
            const assetData = await uploadToAPI(file);
            if (assetData) {
              const { signedUrl, url, id } = assetData;
              editor.AssetManager.add({
                src: signedUrl,
                type: file.type.startsWith("image") ? "image" : "video",
                id,
              });
              setAssets((prev) => [
                ...prev,
                {
                  id,
                  type: file.type.startsWith("image") ? "image" : "video",
                  src: url,
                  signedUrl,
                },
              ]);
            }
          }
        },
        dropzone: true,
      },
      storageManager: {
        type: null,
      },
      deviceManager: {
        devices: [
          {
            id: "desktop",
            name: "Desktop",
            width: "",
          },
          {
            id: "tablet",
            name: "Tablet",
            width: "768px",
            widthMedia: "992px",
          },
          {
            id: "mobilePortrait",
            name: "Mobile portrait",
            width: "320px",
            widthMedia: "575px",
          },
        ],
      },
    });
    editor.DomComponents.addType("link", {
      isComponent: (el) => el.tagName === "A",
      model: {
        defaults: {
          tagName: "a",
          draggable: true,
          droppable: true,
          editable: true,
          content: "Click me",
          traits: [
            {
              type: "text",
              name: "href",
              label: "URL",
              placeholder: "https://example.com",
              changeProp: true,
            },
            {
              type: "select",
              name: "target",
              label: "Open in",
              options: [
                { value: "_self", name: "Same window" },
                { value: "_blank", name: "New window" },
              ],
            },
            {
              type: "text",
              name: "title",
              label: "Tooltip",
              placeholder: "Hover text",
            },
            {
              type: "color",
              name: "color",
              label: "Link Color",
            },
          ],
          attributes: {
            href: "#",
            target: "_self",
            rel: "noopener noreferrer",
          },
        },

        init() {
          this.on("change:attributes:href", this.handleHrefChange);
          this.on("change:attributes:target", this.handleTargetChange);
        },

        handleHrefChange() {
          const href = this.getAttributes().href;
          if (href && href !== "#") {
            if (href.startsWith("http")) {
              this.addAttributes({
                rel: "noopener noreferrer",
                href: href,
              });
            }
          }
        },

        handleTargetChange() {
          const target = this.getAttributes().target;
          if (target === "_blank") {
            this.addAttributes({ rel: "noopener noreferrer" });
          }
        },
      },

      view: {
        events: {
          click: "handleClick",
        },

        handleClick(e) {
          const href = this.model.getAttributes().href;
          if (href && href !== "#") {
            e.preventDefault();
            const target = this.model.getAttributes().target || "_self";
            window.open(href, target);
          }
        },

        onRender() {
          this.el.contentEditable = true;
          this.el.addEventListener("dblclick", () => {
            this.el.focus();
          });
        },
      },
    });

    editor.DomComponents.addType("iframe", {
      model: {
        defaults: {
          tagName: "iframe",
          attributes: {
            src: "",
            frameborder: "0",
            scrolling: "no",
            allowfullscreen: "true",
            width: "100%",
            height: "350px",
          },
          style: {
            height: "350px",
            width: "100%",
          },
          traits: [
            {
              type: "text",
              label: "Source (URL)",
              name: "src",
              placeholder: "https://www.example.com",
            },
            {
              type: "text",
              label: "Width",
              name: "width",
              changeProp: true,
              default: "100%",
            },
            {
              type: "text",
              label: "Height",
              name: "height",
              changeProp: true,
              default: "350px",
            },
            {
              type: "checkbox",
              label: "Allow Fullscreen",
              name: "allowfullscreen",
              valueTrue: "true",
              valueFalse: "false",
            },
          ],
        },
        init() {
          this.on("change:width", this.updateDimensions);
          this.on("change:height", this.updateDimensions);
          this.on("change:allowfullscreen", this.updateFullscreen);
        },
        updateDimensions() {
          const width = this.get("width") || "100%";
          const height = this.get("height") || "350px";
          this.addStyle({ width, height });
        },
        updateFullscreen() {
          const allowFullscreen = this.get("allowfullscreen") === "true";
          if (allowFullscreen) {
            this.addAttributes({ allowfullscreen: "" });
          } else {
            this.removeAttributes("allowfullscreen");
          }
        },
      },
    });
    editor.DomComponents.addType("document", {
      model: {
        defaults: {
          tagName: "div",
          attributes: {
            src: "",
            "data-document-type": "application/pdf",
          },
          traits: [
            {
              type: "text",
              label: "Document Source",
              name: "src",
              placeholder: "Document URL",
            },
          ],
          content: `
        <div class="document-preview" style="display: flex; align-items: center; justify-content: center;">
          <span>Click to Upload Document</span>
        </div>
      `,
        },
        init() {
          this.on("change:src", this.updateDocumentPreview);
        },
        updateDocumentPreview() {
          const src = this.get("src");
          if (src) {
            this.set(
              "content",
              `<object data="${src}" type="application/pdf" width="800" height="400"></object>`
            );
          }
        },
      },
      view: {
        events: {
          click: "openUploadModal",
        },
        openUploadModal(e) {
          if (this.el.querySelector("object")) {
            return;
          }
          const model = this.model;
          const fileInput = document.createElement("input");
          fileInput.type = "file";
          fileInput.accept = ".pdf";
          fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            if (file) {
              try {
                const uploadedFile = await uploadToAPI(file);
                if (uploadedFile) {
                  const { signedUrl, url, id } = uploadedFile;
                  editor.AssetManager.add({
                    src: signedUrl,
                    type: "document",
                    id,
                  });
                  setAssets((prev) => [
                    ...prev,
                    { id, type: "document", src: url, signedUrl },
                  ]);
                  model.set("src", signedUrl);
                  model.addAttributes({
                    "data-document-type": file.type,
                  });
                }
              } catch (error) {
                console.error(error);
                toaster.error("Document upload failed");
              }
            }
          };
          fileInput.click();
        },
      },
    });

    const customStyles = `body {
      padding: 20px;
      box-sizing: border-box;
    }
      a {
    color: #d983a6;
    text-decoration: underline;
    cursor: pointer;
  }
  a:hover {
    text-decoration: none;
  }`;
    const combinedStyles =
      customStyles + initialData.editorStyles ? initialData.editorStyles : "";
    initialData.editorHtml && editor.setComponents(initialData.editorHtml);
    editor.setStyle(combinedStyles);
    setEditorInstance(editor);
    const mediaSources = initialData.editorComponents
      ? extractMediaComponents(JSON.parse(initialData.editorComponents))
      : null;
    if (mediaSources && mediaSources.length > 0) {
      getSignedUrls(mediaSources).then((data) => {
        let editCaseAssets = [];
        if (data?.content?.length > 0) {
          data?.content?.map((obj) => {
            setAssets((prev) => [
              ...prev,
              {
                id: obj?.id,
                type: obj?.type?.startsWith("document")
                  ? "document"
                  : obj?.type?.startsWith("image")
                  ? "image"
                  : "video",
                src: obj?.url,
                signedUrl: obj?.signedUrl,
              },
            ]);
            editCaseAssets.push({
              id: obj?.id,
              type: obj?.type?.startsWith("document")
                ? "document"
                : obj?.type?.startsWith("image")
                ? "image"
                : "video",
              src: obj?.signedUrl,
              signedUrl: obj?.url,
            });
          });
          const editorComponents = JSON.stringify(editor?.getComponents());
          const editorStyles = JSON.stringify(editor?.getStyle());
          const editorHtml = editor?.getHtml();

          const updatedComponents = replaceSrcInComp_Assets(
            JSON.parse(editorComponents),
            editCaseAssets
          );
          editor.setComponents(updatedComponents);
          editor.setStyle(editorStyles);
        }
      });
    }
  }, []);

  const replaceMediaUrlsInJson = (jsonString, assets) => {
    try {
      const parsed = JSON.parse(jsonString);

      const replaceUrls = (obj) => {
        if (Array.isArray(obj)) {
          return obj.map((item) => replaceUrls(item));
        }

        if (typeof obj === "object" && obj !== null) {
          const newObj = {};
          for (const key in obj) {
            if (key === "src" || key === "data") {
              const asset = assets.find((a) => a.signedUrl === obj[key]);
              newObj[key] = asset ? asset.src : obj[key];
            } else if (key === "content") {
              if (obj.type === "document") {
                const srcValue = obj.attributes?.src || obj.src;
                const asset = assets.find((a) => a.signedUrl === srcValue);
                if (asset) {
                  newObj[
                    key
                  ] = `<object data="${asset.src}" type="application/pdf" width="800" height="400"></object>`;
                } else {
                  newObj[key] = obj[key];
                }
              } else if (obj.type === "image" || obj.type === "video") {
                const srcValue = obj.attributes?.src || obj.src;
                const asset = assets.find((a) => a.signedUrl === srcValue);
                if (asset) {
                  newObj[key] = obj[key].replace(srcValue, asset.src);
                } else {
                  newObj[key] = obj[key];
                }
              } else {
                newObj[key] = obj[key];
              }
            } else if (
              key === "attributes" &&
              (obj.type === "image" ||
                obj.type === "video" ||
                obj.type === "document")
            ) {
              newObj[key] = { ...obj[key] };
              if (newObj[key].src) {
                const asset = assets.find(
                  (a) => a.signedUrl === newObj[key].src
                );
                if (asset) {
                  newObj[key].src = asset.src;
                }
              }
            } else {
              newObj[key] = replaceUrls(obj[key]);
            }
          }
          return newObj;
        }

        return obj;
      };

      return JSON.stringify(replaceUrls(parsed));
    } catch (error) {
      console.error("Error processing JSON:", error);
      return jsonString;
    }
  };

  const replaceSrcInHtml = (html, components, assets) => {
    let updatedHtml = html;
    components.forEach((comp) => {
      if ((comp.attributes && comp.attributes.src) || comp.src) {
        const signedUrl = comp.attributes?.src || comp.src;
        const finalUrl = assets.find(
          (asset) => asset.signedUrl === signedUrl
        )?.src;
        if (finalUrl) {
          const escapedSignedUrl = signedUrl.replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
          );
          const regex = new RegExp(escapedSignedUrl, "g");
          updatedHtml = updatedHtml.replace(regex, finalUrl);
        }
      } else if (comp.attributes && comp.attributes.data) {
        const signedUrl = comp.attributes?.data;
        const finalUrl = assets.find(
          (asset) => asset.signedUrl === signedUrl
        )?.src;
        if (finalUrl) {
          const escapedSignedUrl = signedUrl.replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
          );
          const regex = new RegExp(escapedSignedUrl, "g");
          updatedHtml = updatedHtml.replace(regex, finalUrl);
        }
      }
      if (comp.components && comp.components.length > 0) {
        updatedHtml = replaceSrcInHtml(updatedHtml, comp.components, assets);
      }
    });

    return updatedHtml;
  };
  const replaceSrcInComp_Assets = (components, assets) => {
    const replaceSrcRecursively = (comp) => {
      if (comp.type === "image" || comp.type === "video") {
        const asset = assets.find(
          (a) =>
            a.signedUrl === comp.attributes?.src || a.signedUrl === comp.src
        );
        if (asset) {
          if (comp.attributes?.src) {
            comp.attributes.src = asset.src;
          }
          if (comp.src) {
            comp.src = asset.src;
          }
        }
      } else if (
        comp.type === "document" ||
        (comp.attributes &&
          (comp.attributes["data-document-type"] === "application/pdf" ||
            comp.attributes.type === "application/pdf"))
      ) {
        const assetFromSrc = assets.find(
          (a) => a.signedUrl === comp.attributes?.src
        );
        const assetFromData = assets.find(
          (a) => a.signedUrl === comp.attributes?.data
        );

        if (assetFromSrc) {
          comp.attributes.src = assetFromSrc.src;
          comp.content = `<object data="${assetFromSrc.src}" type="application/pdf" width="800" height="400"></object>`;
        }
        if (assetFromData) {
          comp.attributes.data = assetFromData.src;
        }
      }

      if (comp?.components && Array.isArray(comp.components)) {
        comp.components = comp.components.map(replaceSrcRecursively);
      }
      return comp;
    };
    return components.map(replaceSrcRecursively);
  };
  const handleSaveSubmit = async (type) => {
    if (!editorInstance) return;
    if (editorInstance?.getComponents()?.length > 0) {
      const editorHtml = editorInstance?.getHtml();
      const editorCss = editorInstance?.getCss();
      const editorComponents = JSON.stringify(editorInstance?.getComponents());
      const editorStyles = JSON.stringify(editorInstance?.getStyle());
      const editorAssets = JSON.stringify(
        editorInstance?.AssetManager.getAll().map((asset) => asset.toJSON())
      );
      const updatedComponents = replaceMediaUrlsInJson(
        editorComponents,
        assets
      );
      const updatedAssets = replaceMediaUrlsInJson(editorAssets, assets);
      const updatedHtml = replaceSrcInHtml(
        editorHtml,
        JSON.parse(editorComponents),
        assets
      );

      const newEditedComp = {
        editorHtml: updatedHtml,
        editorCss,
        editorComponents: updatedComponents,
        editorStyles,
      };
      if (
        actionType === "edit" &&
        type === "saveSubmit" &&
        JSON.stringify(newEditedComp.editorHtml) ===
          JSON.stringify(initialData.editorHtml) &&
        JSON.stringify(newEditedComp.editorCss) ===
          JSON.stringify(initialData.editorCss) &&
        JSON.stringify(newEditedComp.editorComponents) ===
          JSON.stringify(initialData.editorComponents) &&
        JSON.stringify(newEditedComp.editorStyles) ===
          JSON.stringify(initialData.editorStyles)
      ) {
        toaster.info("No changes detected in the data.");
        return;
      }
      type === "saveSubmit"
        ? setIsSaveSubmitDisabled(true)
        : setIsSaveDraftDisabled(true);
      const requestBody = {
        editorHtml: updatedHtml,
        editorCss,
        editorComponents: updatedComponents,
        editorAssets: updatedAssets,
        editorStyles,
        description: initialEditCaseData?.description,
        featureImage: initialEditCaseData?.featureImage,
        momentIds: initialEditCaseData?.momentIds,
        status: initialEditCaseData?.status,
        tag: initialEditCaseData?.tag || "-",
        title: initialEditCaseData?.title,
      };
      try {
        const folderId = parseInt(localStorage.getItem("folderId"));
        const documentId = initialEditCaseData?.id || 7;
        const response =
          type === "saveSubmit"
            ? await updateDocument(
                requestBody,
                folderId,
                isDraft ? draftDocId : documentId
              )
            : await saveToDraft(
                requestBody,
                folderId,
                isDraft ? draftDocId : documentId
              );
        if (response.data.status === 0) {
          toaster.success(response.data.message);
          setTabSelect("basic_details");
          getDocumentDetalisEditCase();
        } else {
          toaster.error(response.data.error);
        }
      } catch (error) {
        console.error("Error creating document:", error);
      } finally {
        type === "saveSubmit"
          ? setIsSaveSubmitDisabled(false)
          : setIsSaveDraftDisabled(false);
      }
    } else {
      toaster.error("Please add a Block to submit ");
    }
  };
  const [showAnimation, setShowAnimation] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const closeAnimation = () => {
    setFadeOut(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 500);
  };
  return (
    <>
      {isDraft && showAnimation && (
        <>
          <div className={`parent-animated ${fadeOut ? "fade-out" : ""}`}>
            <div className="animated-text-grape">
              {isDraft
                ? "There are some unsaved changes in draft, click publish to save the changes"
                : ""}{" "}
              <CloseIcon
                className="close-icon-styles"
                onClick={closeAnimation}
              />
            </div>
          </div>
        </>
      )}
      <div>
        <div id="gjs"></div>
        <div id="blocks"></div>
        <div className="grapesJS_button" style={{ gap: "10px" }}>
          {actionType === "edit" &&
            initialEditCaseData?.status === "PUBLISH" && (
              <button
                type="submit"
                className="btn px-4 lh-base update-btn mt-2"
                onClick={() => handleSaveSubmit("saveToDraft")}
                disabled={isSaveDraftDisabled || isSaveSubmitDisabled}
              >
                {isSaveDraftDisabled ? (
                  <Loading variant="light" />
                ) : (
                  "Save as Draft"
                )}
              </button>
            )}
          <button
            type="submit"
            className="btn px-4 lh-base update-btn mt-2"
            onClick={() => handleSaveSubmit("saveSubmit")}
            disabled={isSaveSubmitDisabled || isSaveDraftDisabled}
          >
            {isSaveSubmitDisabled ? (
              <Loading variant="light" />
            ) : (
              "Save & Submit"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
export default Grapes;
