import React, { useState, useEffect, useMemo } from "react";
import ReactTable from "../../Commons/Table/ReactTable";
import "../CoachingDashboard/coachingDashboard.css";
import SessionCreateModal from "./SessionCreateModal";
import SessionUpdateModal from "./SessionUpdateModal";
import SessionUploadModal from "./SessionUploadModal";
import Loading from "../../Commons/Loading";
import { getCoachingSessions } from "../../Services/coaching.service";
import { format, parseISO } from "date-fns";
import { SETTINGS_COMPONENT_TYPE } from "../../../Constants/Enum";
import CommonSwitchCase from "../../Commons/CommonSwitchCase";
import UpdateSessionStatusModal from "./UpdateSessionStatusModal";
import { USER_DETAIL } from "../../../Constants/constant";

export let SESSION_STATUS_CHANGE;
export const sessionStateHandle = (rowdata) => {
  return `Are you sure you want to change status of ${rowdata?.name} from ${
    rowdata?.status
  } to ${rowdata?.status == "DRAFT" ? "PUBLISHED" : "DRAFT"}?`;
};

function Sessions() {
  const [loader, setLoader] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateSessionStatusModal, setOpenUpdateSessionStatusModal] =
    useState(false);
  const [rowData, setRowData] = useState({});
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [rowDataEditCase, setRowDataEditcase] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [buttonValue, setButtonValue] = useState("");
  const [coachingSessionStats, setCoachingSessionStats] = useState([]);
  const [hasModalClosed, setHasModalClosed] = useState(false);
  const [sessionCreatedOrUpdated, setSessionCreatedOrUpdated] = useState(false);
  const { canRead, canDelete, canWrite, canUpdate } = JSON.parse(localStorage.getItem(USER_DETAIL))?.userPermissions;

  const handleStausEdit = (rowData, buttonValue) => {
    setOpenUpdateSessionStatusModal(true);
    setRowData(rowData);
};

 const getAllCoachingSessionsList = () =>{
  setLoader(true)
      getCoachingSessions().then((response) => {
        if(response?.data?.status === 0){
          setCoachingSessionStats(response.data?.data)
        }
      })
      .catch((error) => {
        console.error(error, "Api error: ");
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (hasModalClosed && sessionCreatedOrUpdated) {
      getAllCoachingSessionsList();
      setHasModalClosed(false);
      setSessionCreatedOrUpdated(false);
    }
  }, [hasModalClosed, sessionCreatedOrUpdated]); 
  useEffect(() => {getAllCoachingSessionsList()}, [])

  const handleUpdateSessions = (rowData) => {
    setOpenUpdateModal(true);
    setRowDataEditcase(rowData);
  };

  const sessionColumn = [
    {
      Header: "S.no",
      accessor: (d, index) => index + 1,
    },
    {
      Header: "Session Name",
      accessor: (d) => d.name,
    },
    {
      Header: "Created By",
      accessor: (d) => d.createdBy,
    },
    // {
    //     Header: "Created At",
    //     accessor: (d) =>{
    //         return format(parseISO(d.createdAt), 'MMMM d, yyyy h:mm a')
    //     },
    // },
    // {
    //     Header: "Updated At",
    //     accessor: (d) =>{
    //         return format(parseISO(d.updatedAt), 'MMMM d, yyyy h:mm a')
    //     },
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => {
        const status = e.value;
        const rowData = e.row.original;

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <p style={{ marginBottom: "0px" }}>{status}</p>{" "}
            {/* Display status */}
            {/* {Status Update on the basis of user permissions} */}
            {(rowData?.status !== "PUBLISH") && (canUpdate && <a
              type="button"
              className="edit-Emp-member"
              onClick={() => handleStausEdit(rowData)}
            >
              <i className="lni lni-pencil mx-1" data-tip="Edit status"></i>
            </a>)}
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      sticky: "right",
      disableSortBy: true,
      Cell: (e) => {
        const rowData = e.row.original;
        return (
          <p className="mb-0 text-center d-inline">
           {rowData?.progressStatus !== "COMPLETED" && (canUpdate && <a
              type="button"
              className="edit-Emp-member"
              onClick={() => {
                handleUpdateSessions(rowData);
              }}
            >
              <i className="lni lni-pencil mx-1" data-tip="Edit Session"></i>
            </a>)}
          </p>
        );
      },
    },
  ];

  return (
    <>
      {modalStatus
        ? CommonSwitchCase.switchCase(
            setModalStatus,
            SETTINGS_COMPONENT_TYPE.MANAGE_COACHING_SESSION,
            buttonValue,
            statusData
          )
        : ""}
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        // <div className="page-wrapper">
        //     <div className="page-content">
        <>
          <div>
            <section className="first-section">
              <div className="">
                <h4 className="heading">Coaching Sessions</h4>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-5">
                    <p>All Sessions Info</p>
                  </div>
                  <div className="col-7 text-end">
                  {canWrite && <div className="btn-group create-button">
                      <button
                        type="button"
                        className="btn text-primary create-btn modal-create-session"
                        onClick={() => setOpenCreateModal(true)}
                      >
                        CREATE SESSION
                      </button>
                    </div>}
                  </div>
                </div>
                <hr />
              </div>
            </section>
            <section className="second-section">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <div className="heading" style={{ margin: "1.7rem 0 0 0" }}>
                      <h4 className="">Sessions Info</h4>
                    </div>
                  </div>
                  <div className="card stats-coaching-dash">
                    <div className="card-body team">
                      <div className="table-responsive">
                        <div className="table-columns-filter-btn">
                          <div className="table-responsive dataTable-style react-table">
                            <ReactTable
                              columns={sessionColumn}
                              data={coachingSessionStats}
                              getAllCoachingSessionsList={getAllCoachingSessionsList}
                              isDelete={canDelete}
                              componentType={
                                SETTINGS_COMPONENT_TYPE.MANAGE_COACHING_SESSION
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {openCreateModal && (
            <SessionCreateModal
              openModal={openCreateModal}
              setOpenModal={setOpenCreateModal}
              setHasModalClosed={setHasModalClosed}
              setSessionCreatedOrUpdated={setSessionCreatedOrUpdated}
            />
          )}
          {openUploadModal && (
            <SessionUploadModal
              openModal={openUploadModal}
              setOpenModal={setOpenUploadModal}
            />
          )}
          {openUpdateModal && (
            <SessionUpdateModal
              openModal={openUpdateModal}
              rowDataEditCase={rowDataEditCase}
              setOpenModal={setOpenUpdateModal}
              sessionId={null}
              setHasModalClosed={setHasModalClosed}
              setSessionCreatedOrUpdated={setSessionCreatedOrUpdated}
            />
          )}
          {openUpdateSessionStatusModal && (
            <UpdateSessionStatusModal
              openUpdateSessionStatusModal={openUpdateSessionStatusModal}
              rowData={rowData}
              setOpenUpdateSessionStatusModal={setOpenUpdateSessionStatusModal}
              getAllCoachingSessionsList={getAllCoachingSessionsList}
            />
          )}
        </>
        //     </div>
        // </div>
      )}
    </>
  );
}

export default Sessions;
