import React, { useEffect, useState } from "react";
import "./knowledgeBase.css";
import Box from "@mui/material/Box";
import QuestionBank from "./QuestionBank";
import FolderAccordianList from "./FolderAccordianList";
import { Modal, Button } from "react-bootstrap";
import { createFolder, getFolderList, updateFolder, deleteFolder } from "../../Services/knowledgeBase.service";
import KnowledgeBaseBanner from "./KnowledgeBaseBanner";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import toaster from '../../Toast/toaster';
import {FormControl, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useForm } from "react-hook-form";

function CoachingKnowledgeBase() {
  const history = useHistory()
  const [folder, setFolder] = useState(history.location.state?.tabValue || 'resource')
  const [selectedRowData, setSelectedRowData] = useState('');
  const [resourceLoading, setResourceLoading] = useState(true)
  const [accordions, setAccordions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editedFolder, setEditedFolder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonValue, setButtonValue] = useState('');
  const [totalCOunt, setTotalCOunt] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [type, setType] = useState('')
  const [hasMoreResources, setHasMoreResources] = useState(true)
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [folderCreated, setFolderCreated] = useState(false)
  const [folderUpdated, setFolderUpdated] = useState(false)
  const [folderDeleted, setFolderDeleted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [delFolder, setDelFolder] = useState(false);
  const [delFolderId, setDelFolderId] = useState('');
  const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;
  const coeData = JSON.parse(localStorage.getItem('COE_DATA'));
  const [maxLimitReached, setMaxLimitReached] = useState(false);
  const [selectedCoeId, setSelectedCoeId] = useState([])
  const [selectedCoe, setSelectedCoe] = useState('Select All');
  
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      folderName : '',
      coeIds: []
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [folderDeleted, folderCreated, folderUpdated]);

  useEffect(() => {
    document.title = "Knowledge Base - Odio";
    let coeId = coeData ? coeData.map(item => {
      return parseInt(item.coeId)
    }) : []
    setSelectedCoeId(coeId)
    localStorage.setItem('COACHING', JSON.stringify({"coeIds": coeId}))
  }, []);
  
  // ReSetting hook state on editing folder
  useEffect(() => {
    if(editedFolder){
      reset({folderName : editedFolder?.name, coeIds : editedFolder?.clientCOEs})
    }
  }, [editedFolder])

  const handleTabSelect = (key) => {
    setFolder(key);
  }

  const handleCreateFolder = () => {
    setOpenModal(true);
  };

  const handleDeleteClick = (id, e, folder, buttonValue) => {
    e.stopPropagation();
    setDelFolderId(id);
    setSelectedRowData(folder);
    setIsOpen(true);
    setButtonValue(buttonValue);
  };
  const handleSubmitFolder = async (data) => {
    const reqBody = {
      name: data.folderName,
      clientCoeIds: data.coeIds?.map((item) => item.coeId),
    };
    if (editedFolder) {
      updateFolder(reqBody, editedFolder?.id)
        .then((response) => {
          if (response?.data?.status === 0) {
            setOpenModal(false);
            setEditedFolder(null);
            setType("");
            setFolderUpdated(true);
            setResetPage(true);
            reset();
          }
          else if(response?.data.status === 1){
            toaster.error(response?.data.message);
          }
        })
        .catch((err) => {
          console.log("Api call error: ", err);
        });
    } else {
      createFolder(reqBody)
        .then((response) => {
          if (response?.data?.status === 0) {
            setOpenModal(false);
            setFolderCreated(true);
            setResetPage(true);
            reset();
            toaster.success("Folder created successfully");
          }  else if(response?.data.status === 1){
            toaster.error(response?.data.message);
          }
        })
        .catch((err) => {
          console.log("Api call error: ", err);
        });
    }
  };

  const getFoldersList = () => {
    setIsScrollLoading(currentPage > 1);
    const reqBody = {
      "coeIds": role === 'AGENT' ? [JSON.parse(localStorage.getItem('coeId'))] : selectedCoeId,
      "input": searchQuery.length > 0 ? searchQuery : undefined,
    }
    getFolderList(reqBody, currentPage, 9).then((response) => {
      if (response?.data?.status === 0) {
        setAccordions((prev) => [...prev, ...response.data.data.data]);
        setHasMoreResources(currentPage < response.data.data.totalPages);
        setTotalCOunt(response?.data?.data?.totalPages)
        setResourceLoading(false)
      } else {
        setResourceLoading(false)
        setIsScrollLoading(false)
      }
    }).
      catch((err) => {
        setIsScrollLoading(false)
      })
  }

  useEffect(() => {
    if (folder === 'resource') {
      if (searchQuery !== "") {
        setSearchQuery("");
      } else {
        setResetPage(true);
      }
    }
  }, [folder, selectedCoeId]);

  useEffect(() => {
    if (delFolder) {
      deleteFolder(delFolderId)
        .then((res) => {
          // toaster.success(res.data.message)
          toaster.success("Folder deleted successfully");
          setDelFolder(false)
          setResetPage(true);
          setDelFolderId('')
        })
        .catch((error) => {
          toaster.error("Unable to delete Folder. Please try again.")
          setDelFolder(false)
          setDelFolderId('')
        })
    }
  }, [delFolder])


  const loadMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setShouldFetch(true);
  };

  const resetFetchFlags = () => {
    setFolderCreated(false);
    setFolderUpdated(false);
    setFolderDeleted(false);
    setDelFolder(false)
    setDelFolderId('')
  };

  const handleInputChange = (e) => {
    const value = e.target?.value || "";
    if (value.length <= 100) {
      setMaxLimitReached(false);
    } else {
      setMaxLimitReached(true);
    }
  };

  useEffect(() => {
    if (folder == 'resource') {
      if (searchQuery.trim().length > 0) {
        const debounceTimeout = setTimeout(() => {
          setResetPage(true);
        }, 800);

        return () => clearTimeout(debounceTimeout);
      } else if (searchQuery.trim().length === 0) {
        setResetPage(true);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    if (folder === 'resource') {
      if (resetPage) {
        setCurrentPage(1);
        setAccordions([]);
        setShouldFetch(true);
        setResetPage(false);
      } else if (currentPage > 1) {
        setShouldFetch(true);
      }
      resetFetchFlags();
    }
  }, [currentPage, resetPage]);

  useEffect(() => {
    if (shouldFetch && (folder == "resource")) {
      setResourceLoading(currentPage === 1);
      getFoldersList();
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSearchQuery(searchQuery);
  };

  const handleSearchClose = () => {
    setSearchQuery('');
  };

  const handleCloseDeleteModal = () => {
    setIsOpen(false)
  }
  const handleCloseModal = () => {
    setOpenModal(false);
    reset({folderName : '', coeIds : []});
    setEditedFolder(null);
  }
  
  return (
    <>
      {openModal && (
       <Modal size="lg" show={openModal} onHide={handleCloseModal} animation={true} aria-labelledby="manager-modal">
       <Modal.Header closeButton>
         <Modal.Title id="manager-modal">{editedFolder ? 'Edit Folder' : 'Create folder'}</Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <Box
           sx={{
             display: "flex",
             flexDirection: "column",
             gap: "1em",
           }}
         >
           {/* Folder Name Input */}
           <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
             <Controller
               name="folderName"
               control={control}
               rules={{
                 required: "Folder Name is required",
                 maxLength: {
                   value: 100,
                   message: "Maximum character limit is 100",
                 },
               }}
               render={({ field }) => (
                 <>
                   <TextField
                     {...field}
                     placeholder="Enter Folder Name"
                     error={!!errors.folderName}
                     helperText={errors.folderName ? errors.folderName.message : `${field.value.length}/100`}
                     inputProps={{ maxLength: 100 }}
                   />
                 </>
               )}
             />
           </Box>
     
           {/* COE Selection */}
           <Box>
             <FormControl sx={{ width: "100%" }}>
               <Controller
                 name="coeIds"
                 control={control}
                 rules={{
                   validate: (value) => value.length > 0 || "Please select at least one COE",
                 }}
                 render={({ field }) => (
                   <Autocomplete
                     multiple
                     options={coeData}
                     getOptionLabel={(option) => option.coeName}
                     onChange={(event, value) => field.onChange(value)}
                     value={field.value}
                     isOptionEqualToValue={(option, value) => option.coeId === value.coeId}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         placeholder="Select COEs"
                         error={!!errors.coeIds}
                         helperText={errors.coeIds ? errors.coeIds.message : ""}
                       />
                     )}
                   />
                 )}
               />
             </FormControl>
           </Box>
     
           {/* Submit Button */}
           <Button
             variant="primary"
             className="custom-create-folder-btn"
             onClick={handleSubmit(handleSubmitFolder)}
             disabled={!Object.keys(errors).length === 0}
           >
             Submit
           </Button>
         </Box>
       </Modal.Body>
     </Modal>     
      )}
      <div className="page-wrapper">
        <div className="page-content">
          <KnowledgeBaseBanner
            folder={folder}
            handleTabSelect={handleTabSelect}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            handleSearchClose={handleSearchClose}
          />
          <div className="container pt-4">
            {folder == "resource" ? (
              <FolderAccordianList
                accordions={accordions}
                handleCreateFolder={handleCreateFolder}
                handleDeleteClick={handleDeleteClick}
                setAccordions={setAccordions}
                editedFolder={editedFolder}
                setEditedFolder={setEditedFolder}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                buttonValue={buttonValue}
                selectedRowData={selectedRowData}
                resourceLoading={resourceLoading}
                moreData={loadMoreData}
                hasMoreData={hasMoreResources}
                scroll={isScrollLoading}
                setResetPage={setResetPage}
                setFolderDeleted={setFolderDeleted}
                folderCreated={folderCreated}
                folderUpdated={folderUpdated}
                folderDeleted={folderDeleted}
                tabValue={folder}
                handleCloseDeleteModal={handleCloseDeleteModal}
                setDelFolder={setDelFolder}
                setSelectedCoeId={setSelectedCoeId}
                setOpenModal={setOpenModal}
                selectedCoe={selectedCoe}
                setSelectedCoe={setSelectedCoe}
              />
            ) : (
              <QuestionBank tabValue={folder} searchQuery={searchQuery} handleSearchClose={handleSearchClose} selectedCoeId={selectedCoeId} setSelectedCoeId={setSelectedCoeId}/>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachingKnowledgeBase;