import { apiCall } from "./Interceptor";
import toast from "../Toast/toaster";
import toaster from "../Toast/toaster";

const GET_USERS = "/odio/api/client/get-users";
const ADD_USER = "/odio/api/user/create";
const PROFILE_UPADTE = "/odio/api/user/update-user-profile";
const GET_PERMISSIONS = "/odio/api/permissions/user-module-permissions";
const UPADTE_USER = "/odio/api/user/update";
const GET_USER_PROFILE = "/odio/api/user/get-user-profile";
const FORGOT_PASSWORD = "/odio/api/user/forgot-password"

const getUserSuggestions = async (value) => {
  let GET_SUGGESTIONS = `/odio/api/user/suggestion?name=${value.val}&status=${value.status}`;
  if (value.coeId != null)
      GET_SUGGESTIONS = `${GET_SUGGESTIONS}&coeId=${value.coeId}`;
  
  const response = await apiCall.get(GET_SUGGESTIONS);
  
  const user = response.data.data[0];
  if (user && user.userExternalId) {
      localStorage.setItem('selectedUserExternalId', user?.userExternalId);
  }

  return response?.data?.data;
}


const getUserPermissions = async () => {
  const response = await apiCall.get(GET_PERMISSIONS);
  return response;
}

const getManagerPermissions = async (value) => {
  let GET_MANAGER_SUGGESTION = `/odio/api/user/managerSuggestion?name=${value.val}&userId=${value.id}`
  const response = await apiCall.get(GET_MANAGER_SUGGESTION)
  return response
}

const getRoleOptions = async () => {
  let role = `/odio/api/user/roles`
  const response = await apiCall.get(role)
  return response
}

const getUsers = async () => {
  return await apiCall.get(GET_USERS);
}

const getUsersProfile = async () => {
  return await apiCall.get(GET_USER_PROFILE);
}

const updateUser = async (data) => {
  await apiCall.put(UPADTE_USER, data)
    .then((response) => {
      if (response) {
        if (response.data.status === 0) {
          toast.success("Successfull");
          return response;
        }
        else if (response.data.status === 500 || response.data.status === 1){
          toaster.error(response?.data?.message)
        }
      }
    })
    .catch((error) => {
      console.error(error);
    })
}

const updateProfile = async (data) => {
  await apiCall
    .put(PROFILE_UPADTE, data)
    .then((response) => {
      if (response) {
        if (response.data.status === 0) {
          toast.success("Successfull");
        }
      }
    })
}

const addUser = async (data) => {
  // const response = await apiCall.post(ADD_USER, data)
  // return response
  await apiCall
    .post(ADD_USER, data)
    .then((response) => {
      if (response) {
        if (response.data.status === 0) {
          toast.success("Successfull");
          return Promise.resolve(response);
        }
      }
    })
    .catch(error => {
      return error.response
    })
}

const forgotPassword = async (data) => {
  return await apiCall
    .post(FORGOT_PASSWORD, data)
    .then((response) => {
      if (response) {
        if (response.data.status === 0) {
          toast.success("OTP sent successfully");
          return response;
        }
      }
    })
}

export default {
  getUserPermissions,
  getUsers,
  updateProfile,
  addUser,
  updateUser,
  getUsersProfile,
  getUserSuggestions,
  getRoleOptions,
  getManagerPermissions,
  forgotPassword,
};
