import React, { useState, useEffect } from 'react';
import { Modal, ModalDialog, DialogTitle, Input, ModalClose, Select, Option } from '@mui/joy';
import toaster from '../../Toast/toaster';
import { adhocMeeting } from '../../Services/meeting.service';
import coeService from '../../Services/coe.service';
import Loading from "../../Commons/Loading";

function MeetingAdHocModal(props) {
    const { openAdHocModal, setOpenAdHocModal } = props;
    const [meetingLink, setMeetingLink] = useState('');
    const [coeData, setCoeData] = useState([]);
    const [selectedCoeId, setSelectedCoeId] = useState('');
    const [loadingCoeData, setLoadingCoeData] = useState(false);

    useEffect(() => {
        const fetchCOEData = async () => {
            setLoadingCoeData(true);
            try {
                const response = await coeService.getCOEList();
                const data = response?.data?.data || [];
                setCoeData(data);
                if (data.length > 0) {
                    setSelectedCoeId(data[0]?.externalId);
                }
                setLoadingCoeData(false);
            } catch (err) {
                toaster.error('Failed to fetch COE data');
                setLoadingCoeData(false);
            }
        };

        if (openAdHocModal) {
            fetchCOEData();
        }
    }, [openAdHocModal]);
    const [loading, setLoading] = useState(false);

    const handlePasteClick = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setMeetingLink(text);
            toaster.success('Pasted Successfully');
        } catch (err) {
            toaster.error('Failed to read clipboard contents');
        }
    };

    const handleJoinMeeting = async () => {
        setLoading(true)
        try {
            if (!meetingLink || !selectedCoeId) {
                toaster.error('Please provide a meeting link and select a COE.');
                return;
            }

            const payload = {
                link: meetingLink,
                coeExtId: selectedCoeId,
            };

            const res = await adhocMeeting(payload);
            toaster.success(res?.data?.data?.message);

            setOpenAdHocModal(false);
            setMeetingLink('');
        } catch (err) {
            toaster.error(err.message || 'Something went wrong');
        }  finally{
            setLoading(false)
        }
    }

    return (
        <Modal
            open={openAdHocModal}
            onClose={() => {
                setOpenAdHocModal(false);
                setMeetingLink('');
            }}
            sx={{
                backdropFilter: 'blur(1px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '15px',
            }}
            disableEscapeKeyDown={true}
        >
            <ModalDialog>
                <ModalClose />
                {loading? (
                    <div className="modal-loading-overlay">
                        <Loading variant="dark" />
                    </div>
                ) : (
                <>
                <DialogTitle>Copy Paste Meeting Link</DialogTitle>
                <DialogTitle className="adhoc-short-title">
                    Invite notetaker ad hoc to a specific meeting
                </DialogTitle>
                <div className="adhoc-input-btn-container">
                    <Input
                        className="adhoc-input"
                        placeholder="Please enter a Zoom/Google Meet/Teams meeting link"
                        sx={{ border: 'none' }}
                        onChange={(e) => setMeetingLink(e.target.value)}
                        value={meetingLink}
                        required
                    />
                    <button className="adhoc-paste-button" onClick={handlePasteClick}>
                        Paste Link
                    </button>
                </div>
                <div className="adhoc-dropdown-container">
                    {loadingCoeData ? (
                        <p>Loading COE data...</p>
                    ) : (
                        <>
                        <p style={{ marginBottom: '8px', fontSize: '14px', fontWeight: 'bold' }}>COE</p>
                        <Select
                            placeholder="Select COE"
                            value={selectedCoeId}
                            onChange={(e, value) => setSelectedCoeId(value)}
                        >
                            {coeData.map((coe) => (
                                <Option key={coe.coeId} value={coe.externalId}>
                                    {coe.coeName}
                                </Option>
                            ))}
                        </Select>
                        </>
                    )}
                </div>
                <div className="adhoc-join-button">
                    <button onClick={handleJoinMeeting}>Join Meeting</button>
                </div>
                </>
                )}
            </ModalDialog>
        </Modal>
    );
}

export default MeetingAdHocModal;
