import { Card, Modal } from "react-bootstrap";
import {useState,useEffect} from 'react'
import { useHistory} from 'react-router-dom'
import { Tooltip } from '@mui/material'

export default function MoreInfo(props){

    const history = useHistory()
    const [historyObject, setHistoryObject] = useState("");
    const [participants,setParticipants] = useState([])

    useEffect(()=>{
        setHistoryObject(history.location.state);
    },[])

    const redirectToUser = (id) => {
        history.push(`/team_member_detail?id=${id}`)
    }
    
    const redirectToGroup = (id,rec,duration,date,prostime,empId,seller,customer,coe,name,userId) => {
        const basePath = props.type === "email" ? "voice_detail" : "sales_call_detail";
        localStorage.setItem("URL",rec)
        history.push(`${basePath}?callId=${id}&name=${name}`,{
            callId:id,
            url:rec,
            duration:duration,
            date:date,
            processingTime: prostime,
            id:empId,
            seller:seller,
            customer:customer,
            // coeId:coeId,
            coe:coe,
            // phone:phone,
            // agentExternalId:externalId,
            userId:userId,
            callName: name,
            // fileName: filename        
        });

        props.closeButton()
    };

    const decimalConversion = (duration) => {
        const [minutes, decimal] = duration.split('.');
        const seconds = Math.round((`0.${decimal}`) * 60); // Convert fractional part to seconds
        return minutes > 0 
        ? `${minutes} min. ${seconds} sec.`
        : `${seconds} sec.`;
      };

    const conversion = (duration)=>{
        let hh = duration?.split(":")[0]
        let mm=duration?.split(":")[1]
        let ss = duration?.split(":")[2]
        let text = ""
        if(mm>0){
          text = `${mm} Min. ${ss} Sec.`
        }
        else if(ss>0){
          text  = text + `${ss} Sec.`
        }
        // let text  = minutes + sec
        return text
    }

    const conv=(d)=>{
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? h : "";
        var mDisplay = m > 0 ? m : "";
        var sDisplay = s > 0 ? s : "";
        return (
            <>
            {h>0?<span>
              {hDisplay}
              <span style={{fontSize:"10px"}}>{h == 1 ? " hr " : " hrs "}</span>
            </span>:""} 
            {m>0?<span>
              {mDisplay}
              <span style={{fontSize:"10px"}}>{m == 1 ? " min " : " mins "}</span>
            </span>:""}
            {s>0?<span>
              {sDisplay}
              <span style={{fontSize:"10px"}}>{s == 1 ? " sec" : " secs"}</span>
            </span>:""}
            </>
        )
    }
    
    useEffect(()=>{
        const participantsList = props?.participants ? Object.values(props.participants) : [];
        setParticipants(participantsList.length === 0 ? ["no participants available"] : participantsList);
    }, [props])

    return(
        
        <Modal show={props.show} className="modal-dialog1" size="lg" >
            <Modal.Header>
                <button className="btn-close" onClick={props.closeButton}>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="modalcard mb-2">
                    <div className="modalcard-body">
                        <div className="row w-100 m-0">
                            <div className="col-6 ps-0">
                                <ul className="list-group list-group-flush" >
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-user me-1"/>
                                            {`${props?.module==="MEETING"? "Participants" : "Customer"}:`}
                                        </h6>
                                        <span>
                                        {props?.module === "MEETING"   ? `${participants?.shift()}`: `${props?.customerName}`}
                                        {props?.module === "MEETING" ? <Tooltip title={Object.values(participants).join(', ')} arrow placement="top-start"><span> ... </span></Tooltip> : ""}
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-time me-1"/>
                                        Date/Time:
                                        </h6>
                                        <span>{props?.date}</span>
                                    </li>
                                    {props?.talkDuration !== null ? <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-stopwatch me-1"/>
                                        Duration
                                        </h6>
                                        <span>{historyObject ? props?.talkDuration?.includes('.') ?(props?.talkDuration) :  conversion(props?.talkDuration): conv(props?.talkDuration)}</span>
                                    </li> : ''}
                                    {props?.processTime && <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-time me-1"/>
                                        Processing Time:
                                        </h6>
                                        <span>{props?.processTime}
                                            {/* | 14:20 Hrs */}
                                        </span>
                                    </li>}
                                </ul>
                            </div>
                            <div className="col-6">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0" title="Click to redirect to Agent Details" onClick={() => redirectToUser(props?.userId)} style={{cursor:'pointer'}}>
                                            <i className="bx bx-user-voice me-1"/>
                                        {`${props?.sellerName} (${props.module === "MEETING" ? "client" : "agent"})`}
                                        </h6>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-hash me-1"/>
                                        {`${props.module=== "MEETING" ? "Client" : "Agent"}`} Id:
                                        </h6>
                                        <span >{ props?.module==="MEETING"? props?.externalId : props?.sellerId }</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="mb-0">
                                            <i className="bx bx-buildings me-1"/>
                                        COE:
                                        </h6>
                                        <span>{props?.coeName}</span>
                                    </li>
                                    {props?.typeOfCall === "SINGLE_CALL" &&  (
                                        <li
                                            className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                            <h6 className="mb-0">
                                                <i className="bx bx-buildings me-1" />
                                                Group ID:
                                            </h6>
                                            {
                                                props?.module==="EMAIL" ? <span>{props?.groupId}</span> :
                                            <span
                                            onClick={() => redirectToGroup(props?.groupId, props.url, props.talkDuration, props.date, props.processTime, props.sellerId, props.sellerName, props.customerName, props.coeName[0], "GROUP", props.userId)}
                                            style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                {props?.groupId}
                                            </span>
                                            }
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="ps-0">
                                <ul className="ps-0">
                                {props?.url !== null ? props?.typeOfCall=='SINGLE_CALL'?
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap moreInfoClass" >
                                    <h6 className="mb-0">
                                        <i className="bx bx-file me-1"/>
                                    File Name
                                    </h6>
                                    <span >{props?.url}</span>
                                    <div className="ms-auto">
                                    <a  href={props?.url}  className="text-light">
                                            <i className="bx bx-download font-16 align-middle ms-1"/>
                                        </a>
                                    </div>
                                </li>:"" 
                                : '' }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}