import React, { useState, useRef, useEffect } from "react";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Button } from "react-bootstrap";
import "./CallSemantic.css";
import sessionService from "../../../Services/session.service";
import { USER_DETAIL } from "../../../../Constants/constant";
import toaster from "../../../Toast/toaster";

function CallSemantic({ moduleId, sessionId, questionId, setShouldDisable }) {

  const [isRecording, setIsRecording] = useState(false);
  const [isReceivingData, setIsReceivingData] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const isAgent = JSON.parse(localStorage.getItem(USER_DETAIL))?.userRole !== "AGENT"
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioStreamRef = useRef(null);
  const webSocketRef = useRef(null);
  const audioBufferRef = useRef([]);
  const audioRef = useRef(null);

  const handleSubmitSocketId = (socketId) => {
    const reqbody = {
     callTrainingId: socketId,
     moduleId: moduleId,
     questionId: questionId,
     sessionId: sessionId,
    };
    sessionService
     .submitCallTrainingId(reqbody)
     .then((response) => {
      if (response?.data?.status === 0) {
       console.log("succesfully submitted socket id");
      }
      console.log("---",response?.data?.data?.progressStatus);
     })
     .catch((error) => {
      console.log("Api call error of submitting socket id: " + error);
     });
   };
   
  const initializeWebSocket = async () => {
    if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
      return;
    }

    const webSocket = new WebSocket("wss://knowledge-ai.odioiq.com/media");

    webSocket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    webSocket.onmessage = (event) => {
      try {
        if (event?.data) {
          setIsReceivingData(true);
          const socketData = JSON.parse(event.data);
          if(socketData?.event ==="connect"){
            const socketId = socketData?.socketId;
            handleSubmitSocketId(socketId);
          }
          const base64Data = socketData?.data;
          const binaryString = atob(base64Data);
          const bytes = new Uint8Array(binaryString.length);

          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          const newBlob = new Blob([bytes], { type: "audio/mpeg" });
          const blobUrl = URL.createObjectURL(newBlob);
          audioRef.current.src = blobUrl;
          audioRef.current.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
          audioRef.current.onended = () => {
            if (audioRef.current.paused) {
              setIsReceivingData(false);
            }
          };
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
      }
    };

    webSocket.onclose = () => {
      console.log("WebSocket connection closed.");
      webSocketRef.current = null;
      setIsRecording(false);
      setIsLoading(false);
      setIsReceivingData(false);
      toaster.success("Call Ended!")
    };

    webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      webSocketRef.current = null;
      setIsRecording(false);
      setIsLoading(false);
      setIsReceivingData(false);
    };

    webSocketRef.current = webSocket;
  };

  const sendBufferedAudio = () => {
    if (!audioBufferRef.current.length || !webSocketRef.current) return;

    const audioBlob = new Blob(audioBufferRef.current, { type: "audio/webm" });
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Audio = reader.result.split(",")[1];
      if (webSocketRef.current?.readyState === WebSocket.OPEN) {
        webSocketRef.current.send(
          JSON.stringify({
            event: "audio_data",
            data: base64Audio,
          })
        );
      }
    };
    reader.readAsDataURL(audioBlob);

    audioBufferRef.current = [];
  };

  const startRecording = async () => {
    if (isRecording) return;

    try {
      await initializeWebSocket();

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStreamRef.current = stream;

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContextRef.current = audioContext;

      await audioContext.audioWorklet.addModule("/silenceDetectorProcessor.js");
      const workletNode = new AudioWorkletNode(audioContext, "silence-detector");

      const microphone = audioContext.createMediaStreamSource(stream);
      microphone.connect(workletNode);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioBufferRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        if (!isReceivingData) {
          sendBufferedAudio();
        }
      };

      workletNode.port.onmessage = (event) => {
        if (event.data.silent && mediaRecorder.state === "recording") {
          mediaRecorder.stop();
        } else if (!event.data.silent && mediaRecorder.state !== "recording") {
          mediaRecorder.start();
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      setShouldDisable(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    setIsLoading(true);
    setShouldDisable(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop());
      audioStreamRef.current = null;
    }

    if (webSocketRef.current) {
      webSocketRef.current.close();
      webSocketRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      stopRecording();
      setShouldDisable(false);
    };
  }, []);

  useEffect(() => {
    if (!isReceivingData) {
      sendBufferedAudio();
    }
  }, [isReceivingData]);

  return (
    <div>
      <div className="semantic_box">
        <div>
          <Button
            disabled={isLoading || isAgent}
            className="call-end-start-button"
            style={{ backgroundColor: isRecording ? "red" : "#1976d2" }}
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? "End Call" : "Start Call"}
            {isRecording ? <CallEndIcon className="call-end-start-icon" /> : <CallIcon className="call-end-start-icon" />}
          </Button>
          <audio ref={audioRef} />
        </div>
        <div className="container_semantic">
          <div className="parent">
            <div className='phone'>
              <span className="material-icons">
                <MicIcon />
              </span>
            </div>
            <div className={`para circle1 ${isRecording ? 'para_animation' : ''}`}></div>   
            <div className={`para circle2 `}></div>         
          </div>
          <div className="parent1">
            <div className='phone'>
              <span className="material-icons">
                <VolumeUpIcon />
              </span>
            </div>
            <div className={`para circle1 `}></div>   
            <div className={`para circle2 ${isReceivingData ? 'para_animation' : ''}`}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallSemantic;
