import React, { useEffect,useLayoutEffect } from "react";
import { useState } from "react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import "../ManageMoments/manageMoments.css";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ratingService from "../../Services/rating.service";
import ReactTooltip from "react-tooltip";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import Stack from "@mui/material/Stack";
import reportConfigService from "../../Services/reportConfig.service";
import coeService from "../../Services/coe.service";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Select } from "@mui/material";
import WantToDeleteModal from "../../Commons/Modal/WantToDeleteModal";

function ReportGroup(props) {
  const [formError, setFormErrors] = useState({});
  const [sendOn, setSendOn] = useState(props.report?.triggerTime);
  const [isShow, setIsShow] = useState(false);
  const [isShowDate, setIsShowDate] = useState(false);
  const [isShowDay, setIsShowDay] = useState(false);
  const [isShowCustomDate, setIsShowCustomDate] = useState(false);
  const [coeVal, SetCoeVal] = useState();
  const [day, SetDay] = useState(props.report?.weeklyDay);
  const errors = {};
  const [frequency, setFrequency] = useState(props.report?.reportFrequencyId);
  const [selectedCoe, setSelectedCoe] = useState();
  const [date, setDate] = useState();
  const [fromDate, setFromDate] = useState(props.report?.customFromDate);
  const [toDate, setToDate] = useState();
  const [userList, setUserList] = useState();
  const [roleName, setRoleName] = useState();
  const [isDelete, setIsDelete] = useState();
  let [inputs, setInputs] = useState({});

  const daysList = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  var coePara = props.coeList.filter(function (el) {
    return el.coeId == props.report?.coeId;
  });
  coePara = coePara ? coePara[0] : "";

  var reportTypePara;
  if (props.reportType) {
    reportTypePara = props.reportType?.filter(function (el) {
      return el.reportId == props.report?.reportId;
    });
    reportTypePara = reportTypePara[0];
  }

  var frequencyPara = props.frequencyList.map(function (el) {
    return el.frequency_id == props.report?.frequency_id;
  });
  frequencyPara = frequencyPara[0];

  var dayPara = daysList?.filter(function (el) {
    return el.value == props.report?.weeklyDay;
  });
  dayPara = dayPara ? dayPara[0] : "";

  if (props.reports) {
    onIncludeUser(props.report.sentTo);
  }

  useEffect(() => {
    if (selectedCoe) {
      let data = { coeId: selectedCoe, role: roleName ? roleName : "" };
      reportConfigService.userListOfcoe(data).then((res) => {
        let userss = res.map((user) => {
          return {
            label: user.firtsName + "" + user.lastNmae,
            value: user.id,
          };
        });
        return setUserList(userss);
      });
    }
  }, [roleName, selectedCoe]);

  useEffect(() => {
    if (props.report) {
      let index = props.coeList.findIndex(
        (element) => element.coeId == props.report?.coeId
      );
      SetCoeVal(index >= 0 ? props.coeList[index] : "");
    }
  }, [props.report, props.coeList]);

  useEffect(() => {
    if (props.report) {
      let index = props.reportType.findIndex(
        (element) => element.reportId == props.report?.reportId
      );
      SetCoeVal(index >= 0 ? props.coeList[index] : "");
    }
  }, [props.report, props.coeList]);

  useEffect(() => {
    setSelectedCoe(props.report ? props.report?.coeId : "");
    SetDay(
      props.report
        ? props.report.frequency === "WEEKLY"
          ? props.report?.sendOn_day_or_date
          : ""
        : ""
    );
    SetDay(
      props.report
        ? props.report.frequency === "WEEKLY"
          ? props.report?.sendOn_day_or_date
          : ""
        : ""
    );
    setInputs((inputs) => ({
      ...inputs,
      clientId: props.clientId,
    }));
    if (props.report?.frequency === "WEEKLY") {
      setIsShowDay(true);
    } else if (props.report?.frequency === "MONTHLY") {
      setIsShowDate(true);
    } else {
      setIsShowDate(false);
      setIsShowDay(false);
    }
    if (props.report?.frequency === "MONTHLY") {
      let reportDate = props.report?.sendOn_day_or_date;
      reportDate = reportDate.split("/");
      setDate(
        props.report
          ? props.report.frequency === "MONTHLY"
            ? new Date(
                `${reportDate[1]}/${reportDate[0]}/${reportDate[2]} 12:00`
              )
            : ""
          : ""
      );
    }
  }, [props.report]);

  const string_chop = function (str, size) {
    if (str == null) return [];
    str = String(str);
    size = ~~size;
    return size > 0 ? str.match(new RegExp(".{1," + size + "}", "g")) : [str];
  };
  const handleSelect = (event) => {
    var index;
    if (event.target.name === "coeId") {
      index = props.coeList.findIndex(
        (element) => element.coeName == event.target.value
      );
      SetCoeVal(props.coeList[index]);
      setSelectedCoe(props.coeList[index]?.coeId);

      setInputs((inputs) => ({
        ...inputs,
        ["coeId"]: props.coeList[index]?.coeId,
      }));

    } else if (event.target.name === "reportTypeId") {
      index = props.reportType.findIndex(
        (element) => element.reportName == event.target.value
      );
      setInputs((inputs) => ({
        ...inputs,
        ["reportTypeId"]: props.reportType[index]?.reportId,
      }));
    } else if (event.target.name === "frequency") {
      if (event.target.value === "MONTHLY") {
        setIsShowDate(true);
        setIsShowDay(false);
        setIsShowCustomDate(false);
      } else if (event.target.value === "WEEKLY") {
        setIsShowDay(true);
        setIsShowDate(false);
        setIsShowCustomDate(false);
      } else if (event.target.value === "CUSTOM") {
        setIsShowDay(false);
        setIsShowDate(false);
        setIsShowCustomDate(true);
      } else {
        setIsShowDate(false);
        setIsShowDay(false);
        setIsShowCustomDate(false);
      }
      let frequencyIndex = props.frequencyList?.findIndex(
        (element) => element.frequency_type == event.target.value
      );
      if (frequencyIndex != -1) {
        setFrequency(props.frequencyList[frequencyIndex]);
        setInputs((inputs) => ({
          ...inputs,
          ["frequency"]: props.frequencyList[frequencyIndex].frequency_id,
        }));
      }
    } else if (event.target.name === "day") {
      SetDay(event.target.value);
      setInputs((inputs) => ({
        ...inputs,
        ["sendOn_day_or_date"]: event.target.value,
      }));
    } else {
      setInputs((inputs) => ({
        ...inputs,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const onIncludeUser = (e) => {
    var user = e.split(",");
    var ar = user.map((m) => {
      return parseInt(m);
    });
    setInputs((inputs) => ({
      ...inputs,
      includeUserIds: ar,
    }));
  };

  const onIncludeRole = (e) => {
    var role = e.split(",");
    setRoleName(role[0]);
    setInputs((inputs) => ({
      ...inputs,
      roleName: role,
    }));
  };

  const validate = (values) => {
    if (!values.reportTypeId) {
      errors.reportId = "Please select ReportType.";
    }
    if (!values.frequency) {
      errors.frequency = "Please select frequency.";
    }
    if (!values.coeId) {
      errors.coeId = "Please select coe.";
    }
    if (!values.sendOn) {
      errors.sendOn = "Please Select Send on Time";
    }

    if (!values.includeUserIds) {
      errors.includeUserIds = "Please select Inclusive Employees";
    }

    setFormErrors(errors);
    return errors;
  };
  const OnTimeChange = (e) => {
    var hours = e?.getHours();
    var minutes = e?.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    hours = hours < 10 ? "0" + hours : hours;
    var strTime = hours + ":" + minutes + ":00";
    setSendOn(strTime);
    setInputs((inputs) => ({
      ...inputs,
      ["sendOn"]: strTime,
    }));
  };

  const handleDate = (e) => {
    setDate(e);
    let day = e.getDate();
    let month = e.getMonth();
    let year = e.getFullYear();
    let date = `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
    setInputs((inputs) => ({
      ...inputs,
      ["sendOn_day_or_date"]: date,
    }));
  };

  const handleCustomFromDate = (e) => {
    setFromDate(e);
    let day = e.getDate();
    let month = e.getMonth();
    let year = e.getFullYear();
    let date = `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year}`;
    setInputs((inputs) => ({
      ...inputs,
      ["sendOn_customFrom_date"]: date,
    }));
  };

  const handleCustomToDate = (e) => {
    setToDate(e);
    let day = e.getDate();
    let month = e.getMonth();
    let year = e.getFullYear();
    let date = `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year}`;
    setInputs((inputs) => ({
      ...inputs,
      ["sendOn_customTo_date"]: date,
    }));
  };

  const addNewReport = () => {
    if (props.report?.reportId) {
      inputs = { ...inputs };
      reportConfigService.updateReport(inputs).then((res) => {
        reportConfigService.getReportConfig().then((res) => {
          props.setReportDet(res);
        });
      });
    } else {
      validate(inputs);
      const requestBody = {
        clientId: inputs.clientId,
        coeId: inputs.coeId,
        day: inputs.sendOn_day_or_date,
        fromDate: inputs.sendOn_customFrom_date,
        reportId: inputs.reportTypeId,
        reportFrequencyId: frequency?.frequency_id,
        sendTo: inputs.includeUserIds?.toString(),
        toDate: inputs.sendOn_customTo_date,
        triggerDate: inputs.sendOn_day_or_date,
        triggerTime: inputs.sendOn,
      };
      if (Object.keys(errors).length == 0) {
        reportConfigService.createReport(requestBody).then((res) => {
          reportConfigService.getReportConfig().then((res) => {
            props.setReportDet(res);
            props.RemoveForm();
          });
          reportConfigService.gettriggeredReports().then((res) => {
            props.setReportList(res.data.data);
          });
        });
      }
    }
  };
  const deleteReportthis = () => {
    setIsDelete(true);
  };
  const deleteReport = () => {
    if (props.report?.reportId) {
      reportConfigService.deleteReport(props.report.reportId).then((res) => {
        reportConfigService.getReportConfig().then((res) => {
          props.setReportDet(res);
        });
      });
    }
  };
  const roleOptions = [
    { value: "ADMIN", label: "ADMIN" },
    { value: "AGENT", label: "AGENT" },
  ];

  const isCustomFrequency = () => {
    if (frequency?.frequency_type === "CUSTOM") {
      return true;
    }
    if (props.report?.frequency === "CUSTOM") {
      return true;
    }
    return false;
  };

  const handleFrequencyChange = (event, newValue) => {
    if (newValue?.frequency_type === "MONTHLY") {
      setIsShowDate(true);
      setIsShowDay(false);
      setIsShowCustomDate(false);
    } else if (newValue?.frequency_type === "WEEKLY") {
      setIsShowDay(true);
      setIsShowDate(false);
      setIsShowCustomDate(false);
    // } else if (newValue?.frequency_type === "CUSTOM") {
    //   setIsShowDay(false);
    //   setIsShowDate(false);
    //   setIsShowCustomDate(true);
     } else {
      setIsShowDate(false);
      setIsShowDay(false);
      setIsShowCustomDate(false);
    }

    setFrequency(newValue);
    if (newValue) {
      setInputs((inputs) => ({
        ...inputs,
        ["frequency"]: newValue.frequency_id,
      }));
    }
  };

  const filteredFrequencyList = props.frequencyList?.filter(
    (freq) => freq.frequency_type !== "CUSTOM"
  );

  return (
    <div className="card mt-3">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div></div>
          <div className="w-5 del-btn align-items-center ms-4">
            {props.entityType ? (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn creat-rating del-moment-group p-0"
                >
                  <i
                    className="lni lni-checkmark text-primary-blue me-0"
                    data-tip="Create Report"
                    onClick={addNewReport}
                  ></i>
                </button>
                <button
                  type="button"
                  className="btn creat-rating del-moment-group p-0"
                >
                  <i
                    className="lni lni-archive text-primary-blue me-0"
                    data-tip="Delete Form"
                    onClick={props.RemoveForm}
                  ></i>
                </button>
              </div>
            ) : (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn creat-rating del-moment-group p-0"
                >
                  <i
                    className="lni lni-checkmark text-primary-blue me-0"
                    data-tip={"Update Report"}
                    onClick={addNewReport}
                  ></i>
                </button>
                <button
                  type="button"
                  className="btn del-rating del-moment-group p-0 "
                >
                  <i
                    className="lni lni-archive text-primary-blue me-0"
                    data-tip="Delete Report"
                    onClick={deleteReport}
                  ></i>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="w-30">
            <label for="Applies-To" className="form-label">
              Report Type*
            </label>
            <div className="small-dropdown rating-dropdown">
              <Autocomplete
                disablePortal
                disableClearable
                disabled={props.report ? true : ""}
                value={reportTypePara}
                options={props.reportType}
                groupBy={(option) =>
                  String(option.defalt ? "Standard Report" : "Custome Report")
                }
                getOptionLabel={(option) => option.reportName}
                onSelect={handleSelect}
                renderInput={(params) => (
                  <TextField
                    name="reportTypeId"
                    {...params}
                    placeholder="Report Type"
                    label=""
                  />
                )}
              />
            </div>
            <p
              class="my-1 mb-0 lh-1 font-12"
              style={{ color: "red", height: "18px" }}
            >
              <span>{formError.reportId}</span>
            </p>
          </div>
          <div className="w-18">
        <label htmlFor="Applies-To" className="form-label">
          Frequency*
        </label>
        <div className="small-dropdown rating-dropdown">
          <Autocomplete
            disablePortal
            disableClearable
            disabled={props.report ? true : ""}
            options={filteredFrequencyList}
            value={
              props.report && filteredFrequencyList
                ? filteredFrequencyList.find(
                    (x) => x.frequency_id === props.report.reportFrequencyId
                  )
                : frequency
            }
            getOptionLabel={(option) => option.frequency_type}
            onChange={handleFrequencyChange}
            renderInput={(params) => (
              <TextField
                name="frequency"
                {...params}
                placeholder="Frequency"
                label=""
              />
            )}
          />
        </div>
        <p
          className="my-1 mb-0 lh-1 font-12"
          style={{ color: "red", height: "18px" }}
        >
          <span>{formError.frequency}</span>
        </p>
      </div>
          <div className="w-30">
            <label for="Applies-To" className="form-label">
              COE*
            </label>
            <div className="small-dropdown rating-dropdown">
              <Autocomplete
                disablePortal
                disableClearable
                disabled={props.report ? true : ""}
                options={props.coeList}
                value={coePara}
                getOptionLabel={(option) => option.coeName}
                onSelect={handleSelect}
                renderInput={(params) => (
                  <TextField
                    name="coeId"
                    {...params}
                    placeholder="COE"
                    label=""
                  />
                )}
              />
            </div>
            <p
              class="my-1 mb-0 lh-1 font-12"
              style={{ color: "red", height: "18px" }}
            >
              <span>{formError.coeId}</span>
            </p>
          </div>
          {(
            <div className="w-20 mb-2 moment-group-input">
              <label htmlFor="Send-On" className="form-label">
                Send On*
              </label>
              <div className="d-flex">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      label={null}
                      value={new Date(`2018-01-01 ${sendOn}`)}
                      disabled={props.report ? true : ""}
                      onChange={OnTimeChange}
                      name="sendOn"
                      renderInput={(params) => (
                        <TextField
                          className="mx-1"
                          name="sendOn"
                          {...params}
                          placeholder="Send On"
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
              <p
                className="my-1 mb-0 lh-1 font-12"
                style={{ color: "red", height: "18px" }}
              >
                <span>{formError.sendOn}</span>
              </p>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between">
          {isShowDate || props.report?.reportFrequencyId == 3 ? (
            <div>
              <label for="Applies-To" className="form-label">
                Select Date*
              </label>
              <DatePicker
                className="form-control form-control-sm py-1 report-datepicker"
                selected={date}
                onChange={handleDate}
                placeholderText="select date"
                name="startDate"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          ) : (
            ""
          )}
          {isShowCustomDate || props.report?.reportFrequencyId == 4 ? (
            <div className="d-flex">
              <div>
                <label for="Applies-To" className="form-label">
                  From Date*
                </label>
                <DatePicker
                  className="form-control form-control-sm py-1 report-datepicker"
                  selected={fromDate}
                  onChange={handleCustomFromDate}
                  // minDate={new Date()}
                  placeholderText="select from date"
                  name="From Date"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <label for="Applies-To" className="form-label">
                  To Date*
                </label>
                <DatePicker
                  className="form-control form-control-sm py-1 report-datepicker"
                  selected={toDate}
                  onChange={handleCustomToDate}
                  minDate={fromDate}
                  placeholderText="select to date"
                  name="To Date"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {isShowDay || props.report?.reportFrequencyId == 2 ? (
            <div className="w-30">
              <label for="Applies-To" className="form-label">
                Select Day*
              </label>
              <Autocomplete
                disablePortal
                disableClearable
                disabled={props.report ? true : ""}
                className="report-datepicker"
                options={daysList}
                value={dayPara}
                onSelect={handleSelect}
                renderInput={(params) => (
                  <TextField
                    name="day"
                    {...params}
                    placeholder="Day"
                    label=""
                  />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="w-100">
          <div class="pt-3 text-end">
            <a
              href="javascript:;"
              class=" font-16 add-rep-btn"
              onClick={() => setIsShow(!isShow)}
            >
              Send To
            </a>
          </div>
          {isShow || props.report ? (
            <div className="send-to-details">
              <div className="mt-3 d-flex">
              </div>

              <div className="mt-3 mx-1">
                <lable className="form-label">Include Employees*</lable>
                <MultiSelect
                  className="mt-2"
                  disabled={props.report ? true : ""}
                  options={userList}
                  name="INCLUDE_EMPLOYEES"
                  value={inputs.includeUserIds                  }
                  onChange={onIncludeUser}
                />

                <p
                  class="my-1 mb-0 lh-1 font-12"
                  style={{ color: "red", height: "18px" }}
                >
                  <span>{formError.includeUserIds}</span>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <WantToDeleteModal
        show={isDelete}
        close={() => setIsDelete(false)}
        isConform={deleteReport}
      />
      <ReactTooltip place="bottom" type="dark" effect="solid" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usersList: state.manage_users.usersList,
  };
};

export default connect(mapStateToProps)(ReportGroup);
