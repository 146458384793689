import React, { useEffect, useState } from "react";
import "./knowledgeBase.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BasicDetailsDocument from "./BasicDetailsDocument";
import Grapes from "../../Commons/Grape/Grapes";
import { getDocumentDetailsByFolderIdDocId } from "../../Services/knowledgeBase.service";
import conversationService from '../../Services/conversation.service';
import { setDocEditData, setMomemtData, clearDocEditData,clearMomemtData} from "../../../redux-container/knowledgeBase/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import toaster from "../../Toast/toaster";
import Loading from "../../Commons/Loading";
import KnowledgeBaseBanner from './KnowledgeBaseBanner';

const AddDocument = () => {
  const [tabValue, setTabValue] = React.useState("1");
  const [tabSelect,setTabSelect]= React.useState("basic_details")
  const [personName, setPersonName] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const editedDocData = useSelector(
    (state) => state.knowledgeBase.editDocData
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const editCase = history.location?.state?.data;
  const isEditCase = typeof editCase === "object";
  const actionType = history.location?.state?.actionType;
  const folderId = history.location.state?.data?.folderId || history.location.state?.folderData?.folderId
  const folderName=history.location.state?.data?.folderName || history.location.state?.folderData?.folderName
  const parentFolderId= history.location.state?.data?.parentFolderId  || history.location.state?.folderData?.parentFolderId
  const [draftId,setDraftId]= useState(null)
  let documentId;
 const editDocData = useSelector(state => state.knowledgeBase.editDocData);
  
  const handleChange1 = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTabSelect = (key) => {
    setTabSelect(key)
   }

  const handleChange = (event) => {
    const { target: { value } } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const getDocumentDetalisEditCase = () => {
    const folderId =editCase?.folderId || parseInt(localStorage.getItem('folderId'))
    documentId = editCase?.isDraft ? editCase?.parentDocumentId : editCase?.id
    setDraftId(editDocData?.id ? editDocData?.id : documentId)
    return getDocumentDetailsByFolderIdDocId(folderId, editDocData?.id ? editDocData?.id : documentId)
      .then((response) => {
        if (response.data?.status === 0) {
          dispatch(setDocEditData(response?.data?.data));
        } else {
          toaster.error("Error while fetching the data, please try again");
        }
      })
      .catch((error) => {
        console.error(error);
        toaster.error("Error while fetching the data, please try again");
      });
  };

  const getMomentData = () => {
    const momentBucketId = parseInt(localStorage.getItem("MOMENT_ID")) || undefined;
    return conversationService.getCallListingMoments('SALES', momentBucketId)
      .then((res) => {
        if (res.data?.status === 0) {
          dispatch(setMomemtData(res?.data?.data));
        } else {
          toaster.error("Error while fetching the moments, please try again");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (isEditCase) {
      setLoader(true);
      Promise.all([getDocumentDetalisEditCase(), getMomentData()])
        .finally(() => {
          setLoader(false);
        });
    } else {
      setLoader(true);
      getMomentData()
        .finally(() => {
          setLoader(false);
        });
    }
    return ()=> {
      dispatch(clearDocEditData());
      dispatch(clearMomemtData())
    }
  }, [isEditCase,history, dispatch]);

  
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <KnowledgeBaseBanner componentName = {actionType==='edit'?'Edit Document':'Add Document'} tabSelect={tabSelect} handleTabSelect={handleTabSelect} isDisabled={Object.keys(editedDocData).length==0 ? true : false} folderId={parentFolderId} folderName={folderName} />
          {loader ? (
            <div className="loader-container">
              <Loading variant="dark" />
            </div>
          ) : (
            <section className="container pt-4">
              {tabSelect=== 'basic_details'? <BasicDetailsDocument
                        personName={personName}
                        handleChange={handleChange}
                        setPersonName={setPersonName}
                        setTabValue={setTabValue}
                        setTabSelect={setTabSelect}
                        isEditCase={isEditCase}
                        actionType={actionType}
                        getDocumentDetalisEditCase={getDocumentDetalisEditCase}
                        isDraft={editCase?.isDraft}
                        draftDocId ={draftId}
                      />:<Grapes actionType={actionType} isDraft={editCase?.isDraft} draftDocId ={draftId} getDocumentDetalisEditCase={getDocumentDetalisEditCase} setTabSelect={setTabSelect}/>}
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default AddDocument;
