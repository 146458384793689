import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Button from "@mui/joy/Button";
import sessionService from "../../Services/session.service";
import toaster from "../../Toast/toaster";
import QuizIcon from '@mui/icons-material/Quiz';

function EditableAccordion(props) {
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [isQuesEditing, setIsQuesEditing] = useState('')
  const [isLoading, setLoading] = useState(false);
  let accordionData = props?.accordions[props?.index];
  const sessionData = props?.session;
  const isUpdating = props?.isUpdating;
  const [title, setTitle] = useState(props.initialTitle);
  const [marks, setMarks] = useState({})
  const {selectedQuestions, setSelectedQuestions, setSelectedSessions, setAccordions, accordions} = props;
  // const [selectedQue, setSelectedQue] = useState(props.selectedQuestions)
// console.log(selectedQuestions, 'props.selected questions')
// useEffect(() => {
//   if (props.selectedQuestions?.length > 0) {
//     setSelectedQue(props.selectedQuestions);
//   }
// }, [props.selectedQuestions]);

  useEffect(()=>{
    if (accordionData?.score) {
      const transformedMarks = accordionData.score.reduce((acc, item, idx) => {
        acc[item.id] = { id: item.id, maxScore: item.maxScore, questionId: item.questionId }; // Transform each item in the array
        return acc;
      }, {});
      setMarks(transformedMarks); // Set the transformed object in state
    }
  },[accordionData])
  // console.log(accordionData, 'accordionData')

  const handleTitleEditClick = (e) => {
    e.stopPropagation();
    setIsTitleEditing(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    props?.updateTitle(event.target.value);
  };

  const handleMarksEditClick = (quesId)=>{
    setIsQuesEditing(quesId)
  }

  const handleMarksChange = (e, index) => {
    const value = e.target.value; // The current value typed by the user
    setMarks((prev) => ({
      ...prev,
      [index]: { id: index, maxScore: value, questionId: prev?.[index]?.questionId }, 
      // [index]: value, // Update the value for the corresponding question using its index
    }));
    // props?.updateTitle(event.target.value);
  }

  const handleBlur = () => {
    if (title.trim() === "") {
      setTitle(props?.initialTitle);
    }
    setIsTitleEditing(false);
  };

  const handleMarksBlur = () => {
    // if (initialQues.trim() === "") {
    //   setUpdatedQues(initialQues);
    // }
    setIsQuesEditing(null)
    // setIsQuesEditing(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let newReqBody = {
      ...accordionData,
      questionScores: marks==null ? [] : Object.values(marks)
    }
    if (isUpdating && accordionData?.id) {
      return await sessionService
        .updateModule(sessionData, newReqBody)
        .then((res) => {
          if (res?.data.status === 0) {
            toaster.success("Module Successfully Updated");
            const newArr = [...accordions];
            const obj = res?.data?.data;
            const newObj = {...obj,  documents: obj.documents?.map((document) => document.document.id),
              questions: obj.questions?.map((question) => question.question.id),
              score: obj?.questions?.map((ques)=>{return {id:ques?.question?.id, maxScore:ques?.totalScore, questionId : ques?.id}})
          }
            newArr.splice(props?.index, 1, newObj);
            setAccordions(newArr);
            const arr = res?.data?.data?.questions?.reduce((acc, ques) => {
              acc[ques?.question?.id] = { id: ques?.question?.id, maxScore: ques?.totalScore };
              return acc;
            },{});
            const selectedQuesId = res?.data?.data?.questions?.map((ques) => (ques?.question?.id));
            setMarks(arr);
            // setSelectedQue(selectedQuesId)
          }
        })
        .catch((err) => {
          toaster.error("Error in Updating Modules");
        })
        .finally(() => setLoading(false));
    }
    return await sessionService
      .createModule(sessionData, newReqBody)
      .then((res) => {
        if (res?.data.status === 0) {
          toaster.success("Module Successfully Added");
          const newArr = [...accordions];
          newArr.splice(props?.index, 1, res?.data.data);
          setAccordions(newArr);
        }
      })
      .catch((err) => {
        toaster.error("Error in adding Modules");
      })
      .finally(() => setLoading(false));
  };
  const handleDeleteModule = async () => {
    try {
      const res = await sessionService.deleteModule(sessionData, accordionData);
      if (res?.data.status === 0) {
        toaster.success("Module Successfully Deleted!");
        const newArr = [...accordions];
        newArr.splice(props?.index, 1);
        setAccordions(newArr);
        setSelectedQuestions([]);
        setSelectedSessions([]);
      }
    } catch (err) {
      toaster.error("Error in deleting Module!");
    }
  };
  // console.log(marks, 'marks')
  // console.log(props.filteredQuestions, 'filteredQuestions')
 
  return (
    <Accordion expanded={props?.isExpanded} onChange={props?.onAccordionToggle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isTitleEditing ? (
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            onBlur={handleBlur}
            autoFocus // Auto-focus the input when in editing mode
            style={{ fontSize: "1.25rem", border: "none", outline: "none" }}
          />
        ) : (
          <div>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              {title}
              <IconButton
                onClick={handleTitleEditClick}
                size="small"
                sx={{ marginLeft: "10px" }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              {accordionData?.id  && (
                <IconButton
                  onClick={handleDeleteModule}
                  size="small"
                  sx={{ marginLeft: "5px" }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Typography>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        style={{ display: "flex", flexDirection: "column", gap: "10px", wordWrap:'break-word' }}
      >
        <div>
        {Array.isArray(props.filteredQuestions) && props.filteredQuestions.length > 0 ? (
          props.filteredQuestions.filter(question => selectedQuestions?.length>0 && selectedQuestions.includes(question.id))
          .map((question, index)=>(
            <div key={index} style={{ marginBottom: "8px",  display: "flex",alignItems: "center",justifyContent: "space-between", }}>
              <Typography style={{ flex: 1, overflow:'hidden' }}>
                <QuizIcon sx={{ color: '#0b6bcb', fontSize: '24px', marginRight:'6px' }}/>
                {question?.question}
              </Typography>
              {isQuesEditing === question?.id ? (
                <input
                  type="number"
                  value={marks && marks[question?.id]?.maxScore || ''}
                  onChange={(e) => handleMarksChange(e, question?.id)}
                  onBlur={() => handleMarksBlur(question?.id)}
                  autoFocus // Auto-focus the input when in editing mode
                  style={{
                    fontSize: "1rem",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "4px",
                    marginLeft: "16px",
                  }}
                />
              ): (
                <Typography
                  sx={{ marginLeft: "8px", fontSize: "1rem", color: "#555" }}
                  onClick={() => handleMarksEditClick(question?.id)} // Enable editing on click
                  style={{ cursor: "pointer" }}
                >
                  {marks && marks[question?.id]?.maxScore || ''}
                  <IconButton
                    size="small"
                    sx={{ marginLeft: "8px" }}
                  >
                    {marks && marks[question?.id]?.maxScore  ? ( // If marks are already assigned, show Edit icon
                      <EditIcon fontSize="small" />
                    ) : ( // If no marks are assigned, show Plus icon
                      <AddIcon fontSize="small" />
                    )}
                  </IconButton>
                </Typography>
                )
              }
            </div>
          ))
          ) : (
            <Typography>No questions available</Typography>
          )}
          
          {props?.filteredSessions && props.filteredSessions?.length>0 && 
          props?.filteredSessions?.filter((session, index) => props?.selectedSessions?.length>0 &&props?.selectedSessions?.includes(session.id))
          .map((session, index)=>(
            <div key={index} style={{ marginBottom: "8px" }}>
              <Typography>
                <SummarizeIcon
                  sx={{
                    color: "#1976d2",
                    fontSize:'24px',
                    marginRight: "0.4rem",
                    marginBottom: "2px",
                  }}
                />
                {session?.title}
              </Typography>
            </div>
          ))
          }
        
        </div>
        <div style={{ textAlign: "right" }}>
          <Button
            disabled={
              accordionData?.questions?.length === 0 &&
              accordionData?.documents?.length === 0
                ? true
                : false
            }
            type="submit"
            onClick={handleSubmit}
            loading={isLoading}
          >
            {isUpdating && accordionData?.id ? "Update" : "Submit"}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default EditableAccordion;
