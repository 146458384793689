import React, { useEffect, useMemo, useState, useRef } from 'react';
import i from '../../../assets/images/coaching-images/i.png';
import stats1 from '../../../assets/images/coaching-images/stats1.png';
import stats2 from '../../../assets/images/coaching-images/stats2.png';
import stats3 from '../../../assets/images/coaching-images/stats3.png';
import stats4 from '../../../assets/images/coaching-images/stats4.png';
import Loading from '../../Commons/Loading';
import LinearProgress from '@mui/material/LinearProgress';
import {
  getCoachingSessionDetail,
  getCoachingSessions,
} from '../../Services/coaching.service';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import CoachingUserStatsDetail from './CoachingUserStatsDetail';
import { USER_DETAIL } from '../../../Constants/constant';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ height: '0.6rem', borderRadius: '5px' }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function CoachingUserStats() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const name = urlParams.get('name');
  const userId = urlParams.get('userId');
  const [loader, setLoader] = useState(false);
  const [activeCard, setActiveCard] = useState('');
  const targetRef = useRef(null);
  const [moduleLoader, setModuleLoader] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [sessionDetail, setSessionDetail] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);
  // const [totalPages, setTotalPages] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSessions, setFilteredSessions] = useState([]);
  const userDetail = JSON.parse(localStorage.getItem(USER_DETAIL))

  const getCoachingSessionDetails = (index) => {
    setModuleLoader(true);
    getCoachingSessionDetail(index).then((res) => {
      if (res && res.status == 200) {
        setSessionDetail(res?.data?.data);
        setLoader(false);
        setModuleLoader(false);
      }
    });
  };

  // const fetchMoreSessions = () => {
  //   if (currentPage + 1 > totalPages) {
  //     setHasMore(false);
  //     return;
  //   }
  //   getCoachingSessions(userId, currentPage + 1, 2)
  //     .then((res) => {
  //       if (res && res.status === 200) {
  //         const newSessions = res.data?.data?.data;
  //         if (newSessions.length === 0) {
  //           setHasMore(false);
  //         } else {
  //           setSessions((prev) => ({
  //             ...prev,
  //             sessionList: [...prev.sessionList, ...newSessions],
  //           }));
  //           setCurrentPage((prev) => prev + 1);
  //         }
  //       }
  //     })
  //     .catch(() => setHasMore(false));
  // };

  useEffect(() => {
    setLoader(true);
    getCoachingSessions(userId)
      .then((res) => {
        if (res && res.status === 200) {
          const initialSessions = res?.data?.data;
          const totalSessions = initialSessions?.length || 0;

          let completedSessions = 0;
          let notStartedSessions = 0;

          initialSessions.forEach((moment) => {
            if (moment?.progressStatus === 'COMPLETED') {
              completedSessions++;
            }
            if (moment?.progressStatus === 'NOT_STARTED') {
              notStartedSessions++;
            }
          });

          const completionPercentage =
            totalSessions > 0
              ? ((completedSessions / totalSessions) * 100).toFixed(0)
              : 0;
          // setTotalPages(res?.data?.data?.totalPages);

          setSessions({
            sessionList: initialSessions,
            totalSessions,
            completedSessions,
            notStartedSessions,
            completionPercentage,
          });
          if (initialSessions.length > 0) {
            // setModuleLoader(true);
            getCoachingSessionDetails(initialSessions[0]?.id);
            setActiveCard(initialSessions[0]?.id);
          }
        }
      })
      .catch(() => setLoader(false))
      .finally(() => setLoader(false));
  }, []);

  const handleCardClick = (sessionId) => {
    if (targetRef.current) {
      // targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveCard(sessionId);
    if(sessionId !== activeCard){
      // setModuleLoader(true);
      getCoachingSessionDetails(sessionId);
    }
  };
  // Filter sessions 
  useEffect(() => {setFilteredSessions(sessions?.sessionList)}, [sessions])
  const handleSearch = (e) => {
    const query = e.target.value?.toLowerCase();
    setSearchQuery(query);
    setFilteredSessions(sessions?.sessionList?.filter((session) => session.name.toLowerCase().includes(query)));
  }
  const handleClearSearch = () => {
    setSearchQuery('');
    setFilteredSessions(sessions?.sessionList);
  }

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <div className="page-wrapper">
          <div className="page-content">
            <section className="first-section">
              <div className="">
                <h3 className="heading">{name}</h3>
              </div>
              <div className="">
                <div className="row">
                  <div className="">
                    <p>Coaching Progress Detailed User Stats</p>
                  </div>
                </div>
                <hr />
              </div>
            </section>
            <section className="second-section">
              <div className="">
                <div className="row">
                  <div className="parent">
                    <div className="img_section dashStats">
                      <span>
                        <img src={stats1} alt="" />
                      </span>
                      <div className="text_sign">
                        <p className=" d-flex">
                          SESSIONS COMPLETED{" "}
                          <span>
                            <img src={i} alt="" />
                          </span>
                        </p>
                        <h2 className="text-success">{sessions?.completedSessions}</h2>
                      </div>
                    </div>
                    <div className="img_section dashStats">
                      <span>
                        <img src={stats2} alt="" />
                      </span>
                      <div className="text_sign">
                        <p className="d-flex">
                          SESSIONS NOT STARTED
                          <span>
                            <img src={i} alt="" />
                          </span>
                        </p>
                        <h2 className="text-danger">{sessions?.notStartedSessions}</h2>
                      </div>
                    </div>
                    <div className="img_section dashStats">
                      <span>
                        <img src={stats3} alt="" />
                      </span>
                      <div className="text_sign">
                        <p className="text-danger d-flex">
                          COACHING COMPLETION{" "}
                          <span>
                            <img src={i} alt="" />
                          </span>
                        </p>
                        <h2 className="text-primary">{`${sessions?.completionPercentage}%`}</h2>
                      </div>
                    </div>
                    <div className="img_section dashStats">
                      <span>
                        <img src={stats4} alt="" />
                      </span>
                      <div className="text_sign">
                        <p className="d-flex">
                          TOTAL SESSIONS
                          <span>
                            <img src={i} alt="" />
                          </span>
                        </p>
                        <h2 className="text-danger">{sessions?.totalSessions}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="my-3">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="coaching_wrap_box card-body team" style={{ height: "650px" }}>
                        <div className="card mb-0 coach-responsive-block col-6 flex-fill " style={{ height: "auto" }}>
                          <div className="card-body">
                           <div style={{display : 'flex', justifyContent : 'space-between'}}> <div>
                              <p class="h4">Coaching Sessions</p>
                              <p className="">
                                <small>{`${sessions?.notStartedSessions} Coaching Sessions to Complete`}</small>
                              </p>
                            </div>
                            <TextField
                              style={{maxWidth : '50%', borderRadius : '1em'}}
                              value={searchQuery}
                              onChange={handleSearch}
                              placeholder="Search Sessions..."
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{ marginLeft: '8px' }}>
                                    <Search />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {searchQuery && <Clear onClick={handleClearSearch} style={{ cursor: "pointer" }} />}
                                  </InputAdornment>
                                ),
                              }}
                            /></div>
                            <div className="card mb-3" style={{ backgroundColor: "#f7f7f7" }}>
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <p class="h5">{`Hey ${userDetail?.userRole == "AGENT" ? userDetail?.userFirstName : ''}!`}</p>
                                  <p class="h5">{`${sessions?.completedSessions}/${sessions?.totalSessions}`}</p>
                                </div>
                                <p className="mb-1">
                                  <small>{`${sessions?.completedSessions}/${sessions?.totalSessions} sessions are completed`}</small>
                                </p>
                                <LinearProgressWithLabel value={sessions?.completionPercentage} />
                              </div>
                            </div>
                            <p class="h6" style={{ color: "#1976d2" }}>
                              Sessions
                            </p>
                            <div
                              id="scrollableDiv"
                              style={{
                                height: "360px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                border: "1px solid #ddd",
                                padding: "10px",
                              }}
                              className="filter_scroll session-scroll-wrapper"
                            >
                              {/* <InfiniteScroll
                                dataLength={sessions?.sessionList?.length || 0}
                                next={fetchMoreSessions}
                                hasMore={hasMore}
                                loader={
                                  <div className="search-more" style={{ marginTop: "10px" }}>
                                    <BubbleLoader />
                                  </div>
                                }
                                scrollableTarget="scrollableDiv"
                              > */}
                                {filteredSessions?.map((session, index) => (
                                  <div
                                    key={index}
                                    className={`card mb-2 ${
                                      activeCard === session?.id ? "coaching-session-list-card-active" : "coaching-session-list-card"
                                    }`}
                                    onClick={() => handleCardClick(session?.id)}
                                    style={{cursor : 'pointer'}}
                                  >
                                    <div className="card-body">
                                      <div className="handling-body">
                                        <div>
                                          <p className="h5" style={{ marginBottom: "0.2rem" }}>
                                            {session?.name}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="coaching-percentage-info">
                                        <div>
                                          <p>
                                            <small>{session?.comments}</small>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              {/* </InfiniteScroll> */}
                            </div>
                          </div>
                        </div>
                        <div className="card mb-0 coach-responsive-block col-6 flex-fill filter_scroll" style={{ height: "auto" }} ref={targetRef}>
                          {moduleLoader ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
                              <Loading />
                            </div>
                          ) : (
                            <CoachingUserStatsDetail
                              getCoachingSessionDetail={getCoachingSessionDetail}
                              activeCardId={activeCard}
                              sessionDetail={sessionDetail}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default CoachingUserStats;
