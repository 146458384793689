import { useEffect, useState } from "react";
import DocumentBanner from "./DocumentBanner";
import {
  getDocumentList,
  getFolderList,
  deleteDocument,
  deleteFolder,
} from "../../Services/knowledgeBase.service";
import Loading from "../../Commons/Loading";
import NoData from "../../../assets/images/coaching-images/NoData.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { USER_DETAIL } from "../../../Constants/constant";
import "./knowledgeBase.css";
import { Button } from "@mui/joy";
import CreateFolderModal from "./CreateFolderModal";
import { useDispatch } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Input } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { updateFolder } from "../../Services/knowledgeBase.service";
import LoadingButton from "@mui/lab/LoadingButton";
import toaster from "../../Toast/toaster";
import DeleteConfirmationModal from '../../Commons/DeleteConfirmationModal';

function FolderDocumentList(props) {
  const history = useHistory();
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [folderName, setFolderName] = useState(history.location.state?.folderData?.name);
  const [folderID, setFolderID] = useState(+history.location.state?.folderData?.id);
  const [bannerTabList, setBannerTabList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { canRead, canDelete, canWrite, canUpdate } = JSON.parse(localStorage.getItem(USER_DETAIL))?.userPermissions;
  const [editingSubfolderId, setEditingSubfolderId] = useState(null);
  const [editedSubfolderName, setEditedSubfolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const currentCoachingData = JSON.parse(localStorage.getItem("COACHING"))

  const handleSubfolderEditClick = (subfolderId, originalName) => {
    setEditingSubfolderId(subfolderId);
    setEditedSubfolderName(originalName);
  };

  const handleDeleteClick = (document, folderId) => {
    setDocumentToDelete({ ...document, folderId }); 
    setFolderToDelete(null); 
    setShowDeleteModal(true);
  };

  const handleDeleteFolder = () => {
    if (!folderToDelete) {
      toaster.error("Error: Missing folder information.");
      return;
    }
  
    setLoading(true);
  
    deleteFolder(folderToDelete) 
      .then((response) => {
        if (response?.data?.status === 0) {          
          const updatedFolderList = folderList.filter((folder) => folder.id !== folderToDelete);
          setFolderList(updatedFolderList);
          toaster.success("Folder deleted successfully!");
        } else {
          toaster.error(response?.data?.message || "Failed to delete the folder.");
        }
      })
      .catch((err) => toaster.error("Error deleting folder"))
      .finally(() => setLoading(false));
  };

  const handleDeleteFolderClick = (folderId) => {
    setFolderToDelete(folderId); 
    setDocumentToDelete(null); 
    setShowDeleteModal(true); 
  };

  const handleDeleteDocument = () => {
    if (!documentToDelete?.id || !documentToDelete?.folderId) {    
      toaster.error("Error: Missing document or folder information.");
      return;
    }

    setLoading(true);

    deleteDocument(documentToDelete.id, documentToDelete.folderId)
      .then((response) => {      
        if (response?.data?.status === 0) {        
          if (documentToDelete.folderId === folderID) {
            const updatedDocuments = documentList.filter((doc) => doc.id !== documentToDelete.id);
            setDocumentList(updatedDocuments);
          } else {
            const updatedFolders = folderList.map((folder) => {
              if (folder.id === documentToDelete.folderId) {
                return {
                  ...folder,
                  document: folder.document.filter((doc) => doc.id !== documentToDelete.id),
                };
              }
              return folder;
            });
            setFolderList(updatedFolders);
          }
          toaster.success("Document deleted successfully!");
        } else {        
          toaster.error(response?.data?.message || "Failed to delete the document.");
        }
      })
  };


  const handleSubfolderSaveClick = (subfolderId) => {
    const reqBody = {
      name: editedSubfolderName,
      clientCoeIds: currentCoachingData?.coeIds,
    };
    setLoading(true);
    updateFolder(reqBody, subfolderId)
      .then((response) => {
        if (response?.data?.status === 0) {
          const updatedFolders = folderList.map((folder) =>
            folder.id === subfolderId ? { ...folder, title: editedSubfolderName } : folder
          );
          setFolderList(updatedFolders);
          setEditingSubfolderId(null);
          setEditedSubfolderName("");
          toaster.success('Successfully updated!')
        }
      })
      .catch((err) => toaster.error("Error in updating"))
      .finally(() => setLoading(false));
  };

  const handleSubfolderCancelClick = () => {
    setEditingSubfolderId(null);
    setEditedSubfolderName("");
  };
  const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;

  useEffect(() => {
    let tabList = [];
    getFolderList({
      coeIds: role === 'AGENT' ? [JSON.parse(localStorage.getItem('coeId'))] : currentCoachingData?.coeIds,
    })
      .then((res) => {
        tabList = res?.data?.data?.map((item) => ({
          eventKey: item.id,
          title: item.name,
        }));
        setBannerTabList(tabList);
        let fname = tabList?.find(
          (tab) => tab.eventKey === history.location.state?.folderID
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const getDocumentLists = (folderID) => {
    setLoader(true);
    let payload = {
      folderId: folderID,
      coeIds: role === 'AGENT' ? [JSON.parse(localStorage.getItem('coeId'))] : currentCoachingData?.coeIds,
      input: searchQuery.length > 0 ? searchQuery : undefined,
    };
    getDocumentList(payload)
      .then((res) => {
        setDocumentData(res?.data?.data);
        const documents = res?.data?.data?.filter(
          (item) => item.type === "document"
        );
        const folders = res?.data?.data?.filter(
          (item) => item.type === "folder"
        );
        setDocumentList(documents);
        setFolderList(folders);
        setLoader(false);
      })
      .catch((err) => setLoader(false));
  };

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      const debounceTimeout = setTimeout(() => {
        getDocumentLists(folderID);
      }, 800);

      return () => clearTimeout(debounceTimeout);
    } else if (searchQuery.trim().length === 0) {
      getDocumentLists(folderID);
    }
  }, [folderID, searchQuery]);

  const handleTabSelect = (eventKey) => {
    setFolderID(eventKey);
    localStorage.setItem("folderId", eventKey);
    const selectedFolder = bannerTabList.find(
      (tab) => tab.eventKey == eventKey
    );
    setFolderName(selectedFolder?.title || "");
    handleSearchClose();
  };

  const handleEditClick = (rowData, subFolderId, role) => {
    const data = {
      ...rowData,
      folderId: subFolderId ? subFolderId : folderID,
      folderName: folderName,
      parentFolderId: folderID,
    };
    if (canUpdate && role == "update") {
      localStorage.setItem("folderId", subFolderId ? subFolderId : folderID);
      history.push("/document_add", { data: data, actionType: "edit" });
    } else if (canRead === true && role == "view") {
      localStorage.setItem("folderId", subFolderId ? subFolderId : folderID);
      history.push("/document_view", { data: data, actionType: "view" });
    }
  };

  const handleOpen = (id) => {
    const dataToPush = {
      folderId: id ? id : folderID,
      folderName: folderName,
      parentFolderId: folderID,
    };
    history.push("/document_add", {
      folderData: dataToPush,
      actionType: "add",
    });
    localStorage.setItem("folderId", id);
  };

  const handleCreateFolder = () => {
    setShowCreateFolder(true);
  };

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSearchQuery(searchQuery);
  };

  const handleSearchClose = () => {
    setSearchQuery("");
  };

  return (
    <>
      <DeleteConfirmationModal
        openDeleteModal={showDeleteModal}
        handleCloseDeleteModal={() => setShowDeleteModal(false)}
        title={folderToDelete ? "Delete Folder" : "Delete Document"} 
        setDelState={folderToDelete ? handleDeleteFolder : handleDeleteDocument} 
        deleteType={folderToDelete ? 'folder' : 'document'} 
        className="document-delete-modal"
      />
      {showCreateFolder && (
        <CreateFolderModal
          showCreateFolder={showCreateFolder}
          setShowCreateFolder={setShowCreateFolder}
          parentFolderId={folderID}
          getDocumentLists={getDocumentLists}
        />
      )}
      <div className="page-wrapper">
        <div className="page-content">
          <DocumentBanner
            handleTabSelect={handleTabSelect}
            bannerTabList={bannerTabList}
            folderID={folderID}
            folderName={folderName}
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            handleSearchClose={handleSearchClose}
          />
          {loader ? (
            <div className="loader-container">
              <Loading variant="dark" />
            </div>
          ) : (
            <>
              <div className="container">
                {
                  <div className="main-articles-list">
                    <div className="main-article-folder-title d-flex justify-content-between">
                      <div className="d-flex">
                        <span>
                          <i class="bx bx-folder" />
                        </span>
                        <h4>{folderName}</h4>
                      </div>
                      {canWrite && (
                        <div className="d-flex folder-document-main">
                          <Button className="folder-document-main-button" onClick={() => handleCreateFolder()}>
                            Create Folder
                          </Button>
                          <Button className="folder-document-main-button" onClick={() => handleOpen(folderID)}>
                            Create Document
                          </Button>
                        </div>
                      )}
                    </div>
                    {documentList?.length > 0 ? (
                      <div className="folder-document-list">
                        <ul>
                          {documentList.map((doc, index) => (
                            <li key={`${doc.id}-${index}`} className="document-item">
                              <div className="d-flex align-items-center">
                                <a className="single-document-name"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick(doc, undefined, 'view');
                                  }}
                                >
                                  {doc.title}
                                </a>
                                {canUpdate && (
                                  <Tooltip title="Edit Document">
                                    <IconButton onClick={() => handleEditClick(doc, undefined, 'update')}>
                                      <EditIcon sx={{ color: "#0B6BCB" }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {canDelete && (
                                  <Tooltip title="Delete Document">
                                    <IconButton onClick={() => handleDeleteClick(doc, folderID)}>
                                      <DeleteIcon sx={{ color: "#0B6BCB" }}/>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      !folderList?.length && (
                        <div className="d-flex justify-content-center align-items-center no-question-available-main">
                          <img src={NoData} />
                          <span>No document and available to show.</span>
                        </div>
                      )
                    )}
                    {folderList?.length > 0 && (
                      <div className="folder-document-folders">
                        {folderList?.map((folder) => {
                          return (
                            <div className="folders-list-container">
                              <div className="d-flex justify-content-between folders-list-container-title">
                                <div className="d-flex">
                                  <span>
                                    <i className="bx bx-folder" />
                                  </span>
                                  {editingSubfolderId === folder.id ? (
                                    <div className="d-flex align-items-center" style={{ gap: "4px" }}>
                                      <Input
                                        type="text"
                                        value={editedSubfolderName}
                                        onChange={(e) => setEditedSubfolderName(e.target.value)}
                                        placeholder="Enter subfolder name"
                                      />
                                      {loading ? (
                                        <LoadingButton loading={true} />
                                      ) : (
                                        <>
                                          {editedSubfolderName.trim() !== folder.title.trim() && (
                                            <IconButton onClick={() => handleSubfolderSaveClick(folder.id)}>
                                              <DoneIcon />
                                            </IconButton>
                                          )}
                                          <IconButton onClick={handleSubfolderCancelClick}>
                                            <CloseIcon />
                                          </IconButton>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                                      {role === 'AGENT' && folder?.document?.length === 0 ? "" : <h5>{folder.title}</h5>}
                                      {canUpdate && (
                                        <Tooltip title="Edit Subfolder">
                                          <IconButton onClick={() => handleSubfolderEditClick(folder.id, folder.title)}>
                                            <EditIcon sx={{ color: "#0B6BCB" }}/>
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {canDelete && (
                                        <Tooltip title="Delete Subfolder">
                                          <IconButton onClick={() => handleDeleteFolderClick(folder.id)}>
                                            <DeleteIcon sx={{ color: "#0B6BCB" }}/>
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </div>
                                  )}
                                </div>
                                {canWrite && (
                                  <Button className="folders-create-document" onClick={() => handleOpen(folder.id)}>
                                    Create Document
                                  </Button>
                                )}
                              </div>
                              {( 
                                <div className="folders-list-document-lower" style={{ margin: "10px" }}>
                                  <ul>
                                    {folder.document.map((doc, index) => (
                                      <li key={`${folder.id}-${doc.id}-${index}`}>
                                        <div className="d-flex align-items-center">
                                          <a className="single-document-name"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleEditClick(doc, folder.id, 'view');
                                            }}
                                          >
                                            {doc.title}
                                          </a>
                                          {canUpdate && (
                                            <Tooltip title="Edit Document">
                                              <IconButton onClick={() => handleEditClick(doc, folder.id, 'update')}>
                                                <EditIcon sx={{ color: "#0B6BCB" }}/>
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                          {canDelete && (
                                            <Tooltip title="Delete Document">
                                              <IconButton onClick={() => handleDeleteClick(doc, folder.id)}>
                                                <DeleteIcon sx={{ color: "#0B6BCB" }}/>
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                }
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FolderDocumentList;