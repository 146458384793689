import React, { useEffect, useRef, useState } from "react";
import "./coachingDashboard.css";
import CoachingDashboardStats from "./CoachingDashboardStats";
import CoachingDashboardTableStats from "./CoachingDashboardTableStats";
import {
  getAgentsListByCategoryId,
  getCategoryList,
} from "../../Services/coaching.service";
import Loading from "../../Commons/Loading";
import SessionUploadModal from "../CoachingSessions/SessionUploadModal";
import TeamCreateModal from "./TeamCreateModal";
import { Card, Col, Row } from "react-bootstrap";
import { Tab, Tabs, Typography } from "@mui/material";
import Sessions from "../CoachingSessions/Sessions";
import toaster from "../../Toast/toaster";

function CoachingDashboard() {
  const [coachingSessionData, setCoahingSessionData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedCategoryAgentList, setSelectedCategoryAgentList] = useState([]);

  const getCategoryDataList = () => {
    setLoader(true);
    getCategoryList()
      .then((response) => {
        if (response?.data?.status === 0) {
          setCategoryData(response.data?.data);
          setLoader(false);
        } else {
          toaster.error(
            "No Category list available, please create category in settings "
          );
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error, "Api call Error:");
      })
  };
  useEffect(() => {
    document.title = "Coaching - Odio";
    getCategoryDataList();
  }, []);

  const getAgentsList = (categoryId, categoryName)=>{
    getAgentsListByCategoryId({categoryId: categoryId}).then((response) => {
      if (response?.data?.status === 0) {
        setSelectedCategoryAgentList((prevData) => ({
          ...prevData,
          [categoryName]: response?.data?.data // Store each response under the respective categoryId
        }));
      } 
      else {
        toaster.error("Error while fetching the agents data, please try again");
      }
    })
    .catch((error) => {
      console.error("Error fetching agents data:", error);
    })
    .finally(() => {
      setLoader(false);
    });
  }

  useEffect(() => {
    const fetchAgentsForAllCategories = async () => {
      for (const category of categoryData) {
        setLoader(true);
        await getAgentsList(category?.id, category?.name);
      }
    };

    fetchAgentsForAllCategories();
  }, [categoryData]);

  const tabIndexRef = useRef(tabIndex);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    tabIndexRef.current = newValue; // Update the ref value
  };

  return (
    <>
      {loader ? (
        <div className="loader-container">
          <Loading variant="dark" />
        </div>
      ) : (
        <div className="page-wrapper">
          <div className="page-content">
            <Row>
              <Col lg={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ marginBottom: "0" }}
                >
                  Coaching Dashboard
                </Typography>
              </Col>
              <Col lg={12} className="mt-3">
                <Tabs
                  value={tabIndex}
                  onChange={(event, newValue) =>
                    handleTabChange(event, newValue)
                  }
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Teams Stats" />
                  <Tab label="Session Stats" />
                  {/* <Tab label="Knowledge Base" /> */}
                </Tabs>
                <Card>
                  <Card.Body>
                    {tabIndex == 0 && (
                      <div className="">
                        <section className="first-section">
                          <div className="">
                            <h4 className="heading">Teams Dashboard</h4>
                          </div>
                          <div className="">
                            <div className="row">
                              <div className="col-5">
                                <p>All Teams Coaching Progress Stats</p>
                              </div>
                              <div className="col-7 text-end">
                                {/* <div className="btn-group create-button">
                                                                    <button type="button" className="btn text-primary create-btn modal-create-session" onClick={() => setOpenCreateModal(true)}>
                                                                        CREATE TEAM
                                                                    </button>
                                                                </div>
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn text-success create-btn modal-upload-session" onClick={() => setOpenUploadModal(true)}>
                                                                        MODIFY TEAM
                                                                    </button>
                                                                </div> */}
                              </div>
                            </div>
                            <hr />
                          </div>
                        </section>
                        <section className="second-section">
                          <CoachingDashboardStats list={selectedCategoryAgentList}/>
                        </section>
                        <section className="third-section">
                          <CoachingDashboardTableStats
                            list={coachingSessionData}
                            categoryData={categoryData}
                            selectedCategoryAgentList={selectedCategoryAgentList}
                            loader={loader}
                          />
                        </section>
                      </div>
                    )}
                    {tabIndex == 1 && (
                      <div className="">
                        <Sessions />
                      </div>
                    )}
                    {/* {tabIndex == 2 && 
                                            <div className="">
                                                <CoachingKnowledgeBase />
                                            </div>} */}
                  </Card.Body>
                </Card>
              </Col>
              {openCreateModal && (
                <TeamCreateModal
                  openModal={openCreateModal}
                  setOpenModal={setOpenCreateModal}
                />
              )}
              {openUploadModal && (
                <SessionUploadModal
                  openModal={openUploadModal}
                  setOpenModal={setOpenUploadModal}
                />
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default CoachingDashboard;
