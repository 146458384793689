import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CoachingAssessmentUserStats from '../CoachingAssessmentUserStats'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../Commons/Loading";
import SummarizeIcon from "@mui/icons-material/Summarize";


const CoachingUserStatsDetail = ({getCoachingSessionDetail, activeCardId, sessionDetail})=>{
    const [expanded, setExpanded] = useState(null);
    const [loader, setLoader] = useState(false)
    const history = useHistory()

    const handlePanelChange = (panelId) => (event, isExpanded) => {
        setExpanded(isExpanded ? panelId : null);
    };

    const setLoaderTrue = ()=>{
        setLoader(true) 
    }

    const setLoaderFalse = ()=>{
        setLoader(false)
    }

    const getBackgroundColor = (score, total) => {
        // if (!total || total === 0) return 'gray'; 
        const percentage = (score / total) * 100;
        if (percentage <= 50) return 'red';
        if (percentage <= 80) return 'orange';
        return 'green';
    };
    

    const totalModules = sessionDetail?.modules?.length || 0;
    const totalQuestions = sessionDetail?.modules?.reduce((acc, mod) => acc + (mod?.questions?.length || 0), 0) || 0;
    const totalDocuments = sessionDetail?.modules?.reduce((acc, mod) => acc + (mod?.documents?.length || 0), 0) || 0;
    const totalScore = sessionDetail?.totalScore || 0;

    const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;
    const isAgent = role === "AGENT";
    return (
        loader ? 
        <div className="loader-container">
            <Loading variant='dark'/>
        </div>
        :
        <div className='card-body'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="h4">{sessionDetail?.name}</p>
                    <span
                    className="session-details-score-custom badge"
                    style={{ background: getBackgroundColor(sessionDetail?.obtainedScore || 0, totalScore) }}
                    >
                        {`${sessionDetail?.obtainedScore} / ${totalScore}`}
                    </span>

            </div>
            <p className='total-ques-module'>
                <small>
                    {`${totalModules} Module${totalModules !== 1 ? 's' : ''} | 
                    ${totalDocuments} Document${totalDocuments !== 1 ? 's' : ''} |
                    ${totalQuestions} Question${totalQuestions !== 1 ? 's' : ''}`}
                </small>
            </p>
            <div>
                {sessionDetail.modules && sessionDetail?.modules.map((mod,id)=>(
                <Accordion key={id} expanded={expanded == id} onChange={handlePanelChange(id)}>
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ flexShrink: 0 }}>
                            <p className='content_summary'>{mod?.name}</p>
                        </Typography>
                        {mod.questions && mod.questions.length > 0 && (
                                    <div className="div-coaching scoreCommonStyle callScore">
                                        <span
                                            className="badge"
                                            style={{ background: getBackgroundColor(mod?.obtainedScore || 0, mod?.totalScore || 0) }}
                                        >
                                            {mod?.obtainedScore || 0}
                                        </span>
                                    </div>
                        )}

                    </AccordionSummary>
                    {mod.documents && mod.documents.map(doc=>(
                        <AccordionDetails>
                            <SummarizeIcon sx={{ color: '#1976d2' }}/> 
                            <span style={{cursor:'pointer',pointerEvents: !isAgent ? 'none' : 'auto',}} onClick={sessionDetail.progressStatus !== "COMPLETED" ? !isAgent ? undefined : ()=>{history.push('/document_view',{document: doc, redirect:true, redirectFrom : 'coachingUserStats', moduleId : mod?.id, sessionId : sessionDetail?.id});} : null}>l{doc?.title}</span>
                        </AccordionDetails>
                    ))}
                    {mod.questions && 
                    <AccordionDetails>
                        <CoachingAssessmentUserStats isAgent={isAgent} questionList={mod?.questions} moduleId={mod?.id} sessionId={activeCardId} activeCardId={activeCardId} setLoaderFalse={setLoaderFalse} setLoaderTrue={setLoaderTrue} getCoachingSessionDetail={getCoachingSessionDetail}/>
                    </AccordionDetails>}
                </Accordion>))}
                {/* {sessionDetail?.modules && sessionDetail?.modules.length>0 && <Button className='mt-4' style={{backgroundColor: '#1976d2'}} type="submit">Submit</Button>} */}
            </div>
        </div>
    )
}

export default CoachingUserStatsDetail