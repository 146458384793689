import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Row, Col, Card, Tabs, Tab, Button, OverlayTrigger, Popover } from "react-bootstrap";
import ComparativeAnalysisGraph from "./ComparativeAnalysisGraph";
import MomentsVsDaysGraph from "./MomentsVsDaysGraph";
import MomentsVsDurationGraph from "./MomentsVsDurationGraph";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import FormControl from '@mui/material/FormControl';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import "../Insight.css"
import insightService from "../../Services/insight.service";
import dashboardService from "../../Services/dashboard.service";
import profileService from "../../Services/profile.service";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../../Commons/Loading";
import { apiCall } from "../../Services/Interceptor";
import clientService from "../../Services/client.service";
import FilterDrawer from "../../Commons/FilterDrawer";
import { onResetFilter, setDateList, setMoments, setResetState, setUserOptions } from "../../../redux-container/insights/action";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../redux-container/store";
import { positionFormat } from "../../Commons/DateFormatter";

export default function ComparativeAnalysis() {

    let localData = JSON.parse(localStorage.getItem("AGENT"))
    let momentId = parseInt(localStorage.getItem("MOMENT_ID")) || undefined
    let defaultAnalysis = useSelector(state=>state?.insights?.moments)
    const [customInput, SetCustomInput] = useState(localData && localData.selectedRange == 9 ? true : localData && localData.selectedRange == '' ? true : false);
    const [date, setDate] = useState(localData && localData.selectedRange ? localData.selectedRange : localData && localData.selectedRange == '' ? 9 : 5);
    const [dates, setDates] = useState([])
    const [opendp, setOpendp] = useState(false);
    const [opendp1, setOpendp1] = useState(false);
    const crrDate = new Date()
    const [startDate, setStartDate] = useState(localData?.fromD ? new Date(`${localData.fromD.split("-")[1]}-${localData.fromD.split("-")[0]}-${localData.fromD.split("-")[2]}`) : new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [endDate, setEndDate] = useState(localData?.toD ? new Date(`${localData?.toD.split("-")[1]}-${localData?.toD.split("-")[0]}-${localData?.toD.split("-")[2]}`) : new Date())
    const [userList, setUserList] = useState([])
    const [selectedAgent, setSelectedAgent] = useState(localData?.userNames ? localData?.userNames : [{ label: "Select All", value: "Select All" }])
    const [defaultFilteredData, setDefaultFilteredData] = useState([])
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const [analysis, setAnalysis] = useState(localData?.analysis ? localData.analysis : defaultAnalysis[0]?.value);
    const [def, setDef] = useState(false)
    const [event, setEvent] = useState(analysis);
    const [momentName, setMomentName] = useState("Agent Introduced himself");
    const [dateChange, setDateChange] = useState(false)
    const [isExcelPdfButton, setIsExcelPdfButton] = useState(false);
    const [daysData, setDaysData] = useState([]);
    const [durationData, setDurationData] = useState([]);
    const [comparativeStatsList, setComparativeStatsList] = useState([]);
    const [apply, setApply] = useState(false)
    const [reset, setReset] = useState(false)
    const dispatch = useDispatch()
    let filteredData = useSelector(state=>state?.insights?.filterData)
    let options = useSelector(state=>state.insights.userOptions)
    let momentGroupData = useSelector(state=>state?.insights?.momentGroupList)
    let momentData = useSelector(state=>state?.insights?.momentList)
    let chipsData = useSelector(state=>state?.insights?.chipsData)
    let analysisValue = filteredData && filteredData?.analysis ? 
    defaultAnalysis && defaultAnalysis.find(item => item.value === filteredData?.analysis)?.displayName || defaultAnalysis[0]?.displayName :
    defaultAnalysis && defaultAnalysis.find(item => item.value === analysis)?.displayName ;

    useEffect(() => {
        document.title = "Comparative Analysis  - Odio"
    }, [])

    useEffect(() => {
        if (defaultAnalysis?.length) {
          setAnalysis(defaultAnalysis[0]?.value); 
        }
      }, [defaultAnalysis]);

    const getDateAcronym = () => {
        dashboardService.getDateAcronym().then(res => {
            let dateList = res ? res.data ? res.data.data.filter((e) => {
                return e.type == "CURRENT" || e.type == "GLOBAL"
            }) : '' : ''
            setDates(dateList);
            dispatch(setDateList(dateList))
            let dd = dateList[4].fromDate.split("-")[2]
            let mm = dateList[4].fromDate.split("-")[1]
            let yyyy = dateList[4].fromDate.split("-")[0]
            let dateF = `${dd}-${mm}-${yyyy}`
            let dd_1 = dateList[4].toDate.split("-")[2]
            let mm_1 = dateList[4].toDate.split("-")[1]
            let yyyy_1 = dateList[4].toDate.split("-")[0]
            let dateT = `${dd_1}-${mm_1}-${yyyy_1}`
            setDefaultFilteredData(defaultFilteredData => ({
                ...defaultFilteredData,
                fromDate: dateT,
                toDate: dateF
            }))
        })
    }
    const getUserList = async () => {
        const res = await apiCall.get(`${'/odio/api/user/get-user-team-list'}?fromD=${localData?.fromD ? localData.fromD : null}&toD=${localData?.toD ? localData.toD : null}&coeIds=${localData?.selectedCoeRange ? localData.selectedCoeRange : ''}`);
        if (res.data.status === 0) {
            setUserList(res?.data?.data?.team)
            const teamOptions = res?.data?.data?.team.map((e,i)=>
                (
                  {"label":`${e.firstName} ${e.lastName}`,"value":`${e.id}`,"id":e.id}
                ))
            const optionsWithSelectAll = [...teamOptions];
            dispatch(setUserOptions(optionsWithSelectAll))
            let userIds = res?.data?.data?.team.map(item => {
                return item.id
            })
            setDefaultFilteredData(defaultFilteredData => ({ ...defaultFilteredData, id: userIds }))
        } else if (res.data.status === 1) {
            setLoader(false);
        }

    }

    const fetchMoments = () => {
        dispatch(setMoments(momentId));
    };

    const getComparativeAnalysisStats = async () => {
        const res = await apiCall.get("/odio/api/call/insight/ComparativeAnalysisMoments/stats")
        if (res?.data?.status === 0) {
          setComparativeStatsList(res?.data?.data?.data);
        } else if (res?.data?.status === 1) {
          setLoader(false);
        }
    };

    const getComparativeData = () => {
        setLoader(true)
        let localData = JSON.parse(localStorage.getItem("AGENT"))
        let values = {
            "fromD": localData?.fromD ? localData.fromD : defaultFilteredData.fromDate,
            "toD": localData?.toD ? localData.toD : defaultFilteredData.toDate,
            "userId": localData?.userIds ? localData.userIds : defaultFilteredData.id,
            "coeIds": localData?.selectedCoeRange
        }
        insightService.getComparative(values).then(res => {
            if (res) {
                setData(res.data.data)
                setLoader(false)
                setIsExcelPdfButton(true)
                setLoader(false)
                setApply(false)
                setReset(false)
                dispatch(setResetState(false))
            }
        })
        setDateChange(false)
    }

    const fetchData = async () => {
        try {
            setLoader(true);

            const commonPayload = {
                "fromD": filteredData?.fromD ? filteredData.fromD : localData?.fromD ? localData?.fromD : defaultFilteredData?.fromDate,
                "toD": filteredData?.toD ? filteredData.toD : localData?.toD ? localData?.toD : defaultFilteredData?.toDate,
                "momentId": filteredData?.analysis ? filteredData.analysis : localData?.analysis ? localData?.analysis : momentData[0].value,
                "userId": localStorage.getItem('AGENT') ? JSON.parse(localStorage.getItem('AGENT')).userIds : defaultFilteredData.id,
                "coeIds": localData?.selectedCoeRange
            };

            const dayDataPromise = apiCall.post('odio/api/call/insight/ComparativeAnalysisMoments', {
                ...commonPayload,
                "filterStats": "MOMENT_DAY"
            });

            const durationDataPromise = apiCall.post('odio/api/call/insight/ComparativeAnalysisMoments', {
                ...commonPayload,
                "filterStats": "MOMENT_DURATION"
            });

            const [dayDataResponse, durationDataResponse] = await Promise.all([dayDataPromise, durationDataPromise]);

            setDaysData(dayDataResponse?.data?.data);
            setDurationData(durationDataResponse?.data?.data[0]?.durationMomentBucket);
        } catch (error) {
            console.error('Error fetching data:', error);
            setDaysData([]);
            setDurationData([])
        } finally {
            setLoader(false);
            setApply(false)
            setReset(false)
            dispatch(setResetState(false))       
        }
    };

    const handleAnalysis = (event) => {
        setAnalysis(event.target.value)
        const displayName = defaultAnalysis.find((item) => item.value === event.target.value)?.displayName;
        setMomentName(displayName)
        setDef(false)
    }

    useEffect(() => {
        getDateAcronym()
        getUserList()
        fetchMoments()
        getComparativeAnalysisStats()
    }, [])

    useEffect(() => {
        setLoader(true)
        if (defaultFilteredData?.id?.length > 0) {
            localStorage.setItem("AGENT",JSON.stringify({
                "pageSelected":localData?.pageSelected,
                "analysis":localData?.analysis ? localData?.analysis : analysis,
                "productivity":localData?.productivity,
                "fromD":localData&&localData.fromD?localData.fromD:defaultFilteredData.fromDate,
                "toD":localData&&localData.toD?localData.toD:defaultFilteredData.toDate,
                "agentDetailCOE":localData?.agentDetailCOE,
                "selectedRange":localData?.selectedRange,
                "selectedDate":filteredData?.dateName?filteredData?.dateName:localData?.selectedDate,
                // "userIds":localData?.userIds?localData?.userIds:defaultFilteredData.id,
                "userIds": localData?.userIds ? localData?.userIds : defaultFilteredData.id,
                "userNames":localData?.userNames?localData.userNames : options.map(item=>item.label),
                "empId":localData?.employeeId,
                "empName":localData?.user,
                "role":localData?.role,
                "designation":localData?.designation,
                "type":localData?.type,
                "teamCOE":localData?.teamCOE,
                "callsFrom":localData?.callsFrom,
                "callsTo":localData?.callsTo,
                "aht":localData?.aht,
                "callScore":localData?.callScore,
                "pitchScore":localData?.pitchScore,
                "customerName":localData?.customerName,
                "customerMobile":localData?.customerMobile,
                "fileName":localData?.fileName,
                "sellerROS":localData?.sellerROS,
                "sellerName":localData?.sellerName,
                "selectedSeller":localData?.selectedSeller,
                "cScore":localData?.cScore,
                "callHealth":localData?.callHealth,
                "callDuration":localData?.callDuration,
                "words":localData?.words,
                "moment":localData?.moment,
                "momentId":localData?.momentId,
                "pitch":localData?.pitch,
                "prob":localData?.prob,
                "opportunity":localData?.opp,
                "urgency":localData?.urgency,
                "coe":localData?.coe,
                "selectedCoeRange":localData?.selectedCoeRange,
                "selectedCOE":localData?.selectedCOE,
                "momentBucket":localData?.momentBucket,
                "momentBucketId":localData?.momentBucketId,
                "frequency":localData?.frequency,
                "reportType":localData?.reportType
            }))
            fetchData();
        }else if (defaultFilteredData?.id?.length == 0){
            fetchData();
        }
    }, [defaultFilteredData, reset]);


    useEffect(()=>{
        if(apply){
            onSubmitHandler()
        }
        if(reset){
            onResetHandler()
            dispatch(setResetState(true))
        }
    },[apply, reset])

    const onSubmitHandler = ()=>{
        setEvent(analysis)
        localStorage.setItem("AGENT", JSON.stringify({
            "pageSelected": localData?.pageSelected,
            "analysis": localData?.analysis ? localData?.analysis : analysis,
            "productivity": localData?.productivity,
            "fromD": filteredData?.fromD ? filteredData.fromD : localData?.fromD,
            "toD": filteredData?.toD ? filteredData?.toD : localData?.toD,
            "agentDetailCOE": localData?.agentDetailCOE,
            "selectedRange": filteredData?.selectedRange?filteredData?.selectedRange : localData?.selectedRange,
            "selectedDate": filteredData?.dateName ? filteredData?.dateName : localData?.selectedDate,
            "userIds": filteredData?.id ? filteredData?.id : localData?.userIds,
            "userNames": filteredData?.userNames ? filteredData?.userNames : localData?.userNames,
            "empId": localData?.employeeId,
            "empName": localData?.user,
            "role": localData?.role,
            "designation": localData?.designation,
            "type": localData?.type,
            "teamCOE": localData?.teamCOE,
            "callsFrom": localData?.callsFrom,
            "callsTo": localData?.callsTo,
            "aht": localData?.aht,
            "callScore": localData?.callScore,
            "pitchScore": localData?.pitchScore,
            "customerName": localData?.customerName,
            "customerMobile": localData?.customerMobile,
            "fileName": localData?.fileName,
            "sellerROS": localData?.sellerROS,
            "sellerName": localData?.sellerName,
            "selectedSeller": localData?.selectedSeller,
            "cScore": localData?.cScore,
            "callHealth": localData?.callHealth,
            "callDuration": localData?.callDuration,
            "words": localData?.words,
            "moment": localData?.moment,
            "momentId": localData?.momentId,
            "pitch": localData?.pitch,
            "prob": localData?.prob,
            "opportunity": localData?.opp,
            "urgency": localData?.urgency,
            "coe": localData?.coe,
            "selectedCoeRange": localData?.selectedCoeRange,
            "selectedCOE": localData?.selectedCOE,
            "momentBucket": localData?.momentBucket,
            "momentBucketId": localData?.momentBucketId,
            "frequency": localData?.frequency,
            "reportType": localData?.reportType
        }))
        if (userList.length == 0) {
            getUserList()
        }
        // getComparativeData()
        fetchData()
    }

    const onResetHandler = () => {
        const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        dispatch(onResetFilter({"productivity":"Call Score","fromD":`${firstDay.getDate()}-${firstDay.getMonth()+1}-${firstDay.getFullYear()}`,"toD":`${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`,"selectedRange":5,"selectedDate":"This Month"}))
        localStorage.setItem("AGENT", 
            JSON.stringify({ 
                "pageSelected": localData?.pageSelected, 
                // "analysis": "Professional Manner", 
                "analysis":localData?.analysis ? localData?.analysis : analysis,
                "productivity": "Call Score", 
                "fromD": `${firstDay.getDate()}-${firstDay.getMonth() + 1}-${firstDay.getFullYear()}`, 
                "toD": `${new Date().getDate()}-${new Date().getMonth() + 1}-${new Date().getFullYear()}`, 
                "agentDetailCOE": localData?.agentDetailCOE, 
                "selectedRange": 5, 
                "selectedDate": "This Month", 
                "userIds": defaultFilteredData.id, 
                "userNames":options.map(item=>item.value),
                "coe": localData?.coe, 
                "selectedCoeRange": localData?.selectedCoeRange, 
                "selectedCOE": localData?.selectedCOE, 
                "momentBucket": localData?.momentBucket, 
                "momentBucketId": localData?.momentBucketId 
            })
        )
        if (userList.length == 0) {
            getUserList()
        }
        fetchData()
    }

    const createExcel = () => {
        clientService.getExcelFile('COMPARATIVE_ANALYSIS');
    }

    const createPdf = () => {
        clientService.getPdfFile('COMPARATIVE_ANALYSIS');
    }

    const applyFilter = ()=>{
        setApply(true)
    }
    
    const setApplyFalse = ()=>{
        setApply(false)
    }
    
    const resetFilter = ()=>{
        setReset(true)
    }

    const setLoaderTrue = ()=>{
        setLoader(true)
    }

    const popoverr = (val) => (
        <Popover id="popover-trigger-click-root-close" title="right" style={{ "marginLeft": "13px" }}>
            <h3 className="popover-header" style={{ background: 'white', color: 'black' }}>
                {val}
            </h3>
        </Popover>
      )

      const handleExcelDownload=(filterStats)=>{
        let fromD = filteredData?.fromD ? filteredData.fromD : localData?.fromD ? localData?.fromD : defaultFilteredData?.fromDate
        let toD = filteredData?.toD ? filteredData.toD : localData?.toD ? localData?.toD : defaultFilteredData?.toDate
        let momentId = filteredData?.analysis ? filteredData.analysis : localData?.analysis ? localData?.analysis : momentData[0].value
        let userId = localStorage.getItem('AGENT') ? JSON.parse(localStorage.getItem('AGENT')).userIds : defaultFilteredData.id
        let coeIds = localData?.selectedCoeRange
        
        clientService.comparativeExcelDownload(fromD,toD,momentId,userId,coeIds,filterStats);
      };

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    {loader ?
                        <div className="loader-container">
                            <Loading />
                        </div> :
                        <Row>
                            <Col lg={12} className="mb-2">
                                <h2 className="dashboard-headings d-inline">Comparative Analysis</h2>
                                {Array.isArray(chipsData) && chipsData.length>0 ? chipsData.map(item=>(
                                <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                    {`${item?.name}, ${item?.id}`}
                                    <a href="javascript:;"></a>
                                </span>))
                                :
                                <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                All employees are selected
                                <a href="javascript:;"></a>
                                </span>
                                }
                                {filteredData && filteredData?.dateName ? 
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                         {`DATE: ${filteredData?.dateName === "Custom Date" ? `${positionFormat(filteredData?.fromD)} to ${positionFormat(filteredData?.toD) }`: filteredData?.dateName }`}
                                        <a href="javascript:;"></a>
                                    </span>
                                : 
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        <span>DATE: </span>
                                        {`${localData?.selectedDate}`}
                                        <a href="javascript:;"></a>
                                    </span>
                                }
                                    <span class="badge rounded-pill bg-warning bg-gradient text-dark active-filter-on-table">
                                        <span>Analysis : </span>
                                         {analysisValue}
                                        <a href="javascript:;"></a>
                                    </span>
                            </Col>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <Card.Body>
                                                <div className='d-flex mt-4 odio-tableTop' style={{ marginLeft: '1rem' }}>
                                                    {isExcelPdfButton && (
                                                        <>
                                                            {' '}
                                                            <Button
                                                                variant='outline-secondary border-end-0 fw-normal excel font-14'
                                                                onClick={createExcel}
                                                                //   disabled={isDisable ? 'disabled' : ''}
                                                            >
                                                                Excel
                                                            </Button>
                                                            <Button
                                                                variant='outline-secondary font-14 fw-normal pdf'
                                                                onClick={createPdf}
                                                            //   disabled={isDisable ? 'disabled' : ''}
                                                            >
                                                                Pdf
                                                            </Button>{' '}
                                                        </>
                                                    )}
                                                </div>
                                                <button
                                                    onClick={() => handleExcelDownload("MOMENT_DAY")}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        backgroundColor: "#ffffff",
                                                        padding: "0px 8px",
                                                        borderRadius: "4px",
                                                        border: "none",
                                                        marginLeft: "4px",
                                                    }}
                                                    >
                                                    <i
                                                        className="bx bx-download"
                                                        style={{
                                                        color: "#0077b6",
                                                        fontSize: "20px",
                                                        fontWeight: "600",
                                                        }}
                                                    ></i>
                                                </button>
                                                {/* <div className="CompAnalysis-section">
                                                    <ComparativeAnalysisGraph loader={loader} data={data} analysis={analysis} def={def} event={event} momentName={momentName} />
                                                </div> */}
                                                <div className="mt-5">
                                                    <div className="d-flex justify-content-between">
                                                        <h5>Moment vs Days<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(comparativeStatsList?.[1]?.value)} rootClose>
                                                            <i className="fadeIn animated bx bx-info-circle text-primary-blue" style={{ fontSize: "1rem", marginLeft: '10px' }}></i>
                                                        </OverlayTrigger></h5>
                                                    </div>
                                                    <MomentsVsDaysGraph loader={loader} data={daysData} analysis={analysisValue}/>
                                                </div>
                                                <button
                                                    onClick={() => handleExcelDownload("MOMENT_DURATION")}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        backgroundColor: "#ffffff",
                                                        padding: "0px 8px",
                                                        borderRadius: "4px",
                                                        border: "none",
                                                        marginLeft: "4px",
                                                    }}
                                                    >
                                                    <i
                                                        className="bx bx-download"
                                                        style={{
                                                        color: "#0077b6",
                                                        fontSize: "20px",
                                                        fontWeight: "600",
                                                        }}
                                                    ></i>
                                                </button>
                                                <div className="mt-5">
                                                    <div className="d-flex justify-content-between">
                                                        <h5>Moment vs Duration<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverr(comparativeStatsList?.[0]?.value)} rootClose>
                                                            <i className="fadeIn animated bx bx-info-circle text-primary-blue" style={{ fontSize: "1rem", marginLeft: '10px' }}></i>
                                                        </OverlayTrigger></h5>
                                                    </div>
                                                    <MomentsVsDurationGraph loader={loader} data={durationData} analysis={analysisValue}/>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
            <FilterDrawer filterType='INSIGHTS' componentType='COMPARATIVE ANALYSIS' applyFilter={applyFilter} setLoaderTrue={setLoaderTrue} resetFilter={resetFilter}/>
        </>
    )
}