import { Form } from 'react-bootstrap';
import "./knowledgeBase.css";

function CoeSelection(props) {
    const coeData = JSON.parse(localStorage.getItem('COE_DATA'));

    const handleCoeSelect = (e) => {
        props?.setSelectedCoe(e.target.value);
        let coes = e.target.value == 'Select All' ? coeData : coeData?.filter(function (el) {
            return el.coeId == e.target.value
        })
        props?.setSelectedCoeId(e.target.value == 'Select All' ? coes.map(item => { return parseInt(item.coeId) }) : [parseInt(e.target.value)])
        const currentCoachingData = JSON.parse(localStorage.getItem("COACHING"))
        localStorage.setItem('COACHING', JSON.stringify({...currentCoachingData, coeIds: e.target.value == 'Select All' ? coes.map(item => { return parseInt(item.coeId) }) : [parseInt(e.target.value)]}))
    }

    return (
        <div className='d-flex'>
        <i class='bx bx-filter coe-selection-icon' ></i> 
        <Form.Select as='select' custom
            aria-label="Default select example"
            className="form-control-sm py-1 coe-selection-form"
            placeholder="Select COE"
            value={props?.selectedCoe}
            onChange={handleCoeSelect}
        >
            <option value={'Select All'}>Select All</option>
            {coeData ? coeData.map((coe) => {
                return <option value={coe.coeId} key={coe.coeId}>{coe.coeName}</option>;
            }) : ''}
        </Form.Select>
        </div>
    )
}

export default CoeSelection