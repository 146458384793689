import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { createQuestionBank, getQuestionBankList } from "../../Services/questionBank.service";
import Box from "@mui/material/Box";
import { Input } from "@mui/joy";
import { toast } from "react-toastify";
import toaster from '../../Toast/toaster';
import { Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {FormControl, FormLabel, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { updateQuestionBank } from "../../Services/questionBank.service";

const CreateQuestionBankModal = ({ openQueModal, setOpenQueModalFalse, questionSetCreated, selectedCoeId, setEditedQuestionSet, editedQuestionSet, setQBankUpdated, setResetPage }) => {

  const [questionFolder, setQuestionFolder] = useState('')
  const [maxLimitReached, setMaxLimitReached] = useState(false);
  const [existingQuestionSets, setExistingQuestionSets] = useState([]);
  const coeData = JSON.parse(localStorage.getItem("COE_DATA"));
  const currentCoachingData = JSON.parse(localStorage.getItem("COACHING"))
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      questionSetName : '',
      coeIds: []
    },
  });
 // Resetting Question state in hook-form on editing
  useEffect(() => {
    if(editedQuestionSet){
      reset({questionSetName : editedQuestionSet?.name, coeIds : editedQuestionSet?.clientCOEs})
    }
  }, [editedQuestionSet])

  const handleInputChange = (e) => {
    const value = e.target?.value || "";
    if (value.length <= 100) {
      setQuestionFolder(value);
      setMaxLimitReached(false);
    } else {
      setMaxLimitReached(true);
    }
  };

  const handleSubmitQuestionSet = async (data) => {
    // if (existingQuestionSets.includes(questionFolder.toLowerCase())) {
    //   toaster.error("Question set name must be unique");
    //   return;
    // }
    const reqBody = {
      name: data?.questionSetName,
      coeIds: data?.coeIds?.map((item) => item.coeId),
    };
    if (editedQuestionSet) {
      updateQuestionBank(editedQuestionSet?.id, reqBody).then((res) => {
        if (res && res?.data?.status === 0) {
          setOpenQueModalFalse();
          setResetPage(true);
          setQBankUpdated(true);
          reset();
          toaster.success("Question set updated successfully");
        }  else if(res?.data.status === 1){
          toaster.error(res?.data.message);
        }
      });
    } else {
      createQuestionBank(reqBody)
        .then((response) => {
          if (response?.data?.status === 0) {
            setOpenQueModalFalse();
            setQuestionFolder("");
            questionSetCreated();
            setEditedQuestionSet(null);
            reset();
            toaster.success("Question set Created Successfully");
          }  else if(response?.data.status === 1){
            toaster.error(response?.data.message);
          }
        })
        .catch((err) => {
          console.log("Api call error: ", err);
        });
    }
  };

  useEffect(() => {
    // Fetch existing question sets to validate uniqueness
    getQuestionBankList(
      {
        coeIds: currentCoachingData?.coeIds || [],
      },
      1, // Fetch the first page
      1000 // Assume a large number to fetch all sets
    )
      .then((response) => {
        if (response?.status === 200) {
          setExistingQuestionSets(
            response?.data?.data?.data.map((q) => q.name.toLowerCase())
          );
        }
      })
      .catch((err) => console.error("Error fetching question sets:", err));
  }, []);
  const handleCloseModal = () => {
    setOpenQueModalFalse();
    reset({questionSetName : '', coeIds : []});
    setEditedQuestionSet(null);
  }
  return (
    <Modal
      size="lg"
      show={openQueModal}
      onHide={handleCloseModal}
      dialogClassName="create-qBank-modal"
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id="manager-modal">
          <h5>{editedQuestionSet ? 'Edit Question Set' : 'Create Question Set'}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
         <Box
           sx={{
             display: "flex",
             flexDirection: "column",
             gap: "1em",
           }}
         >
           <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
             <Controller
               name="questionSetName"
               control={control}
               rules={{
                 required: "Question Set name is required",
                 maxLength: {
                   value: 100,
                   message: "Maximum character limit is 100",
                 },
               }}
               render={({ field }) => (
                 <>
                   <TextField
                     {...field}
                     placeholder="Enter Question set Name"
                     error={!!errors.questionSetName}
                     helperText={errors.questionSetName ? errors.questionSetName.message : `${field.value.length}/100`}
                     inputProps={{ maxLength: 100 }}
                   />
                 </>
               )}
             />
           </Box>
     
           {/* COE Selection */}
           <Box>
             <FormControl sx={{ width: "100%" }}>
               <Controller
                 name="coeIds"
                 control={control}
                 rules={{
                   validate: (value) => value.length > 0 || "Please select at least one COE",
                 }}
                 render={({ field }) => (
                   <Autocomplete
                     multiple
                     options={coeData}
                     getOptionLabel={(option) => option.coeName}
                     onChange={(event, value) => field.onChange(value)}
                     value={field.value}
                     isOptionEqualToValue={(option, value) => option.coeId === value.coeId}
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         placeholder="Select COEs"
                         error={!!errors.coeIds}
                         helperText={errors.coeIds ? errors.coeIds.message : ""}
                       />
                     )}
                   />
                 )}
               />
             </FormControl>
           </Box>
     
           {/* Submit Button */}
           <Button
             variant="primary"
             className="custom-create-folder-btn"
             onClick={handleSubmit(handleSubmitQuestionSet)}
           >
             Submit
           </Button>
         </Box>
       </Modal.Body>
    </Modal>
  )
}

export default CreateQuestionBankModal