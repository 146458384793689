import React, { useEffect, useRef, useState } from "react";
import "./knowledgeBase.css";
import { Typography, IconButton, Tooltip } from "@mui/material";
import { Button } from '@mui/joy';
import { useHistory } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from "../../Commons/Loading";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InfiniteScroll from 'react-infinite-scroll-component';
import BubbleLoader from '../../Commons/BubbleLoader';
import { USER_DETAIL } from "../../../Constants/constant";
import DeleteConfirmationModal from '../../Commons/DeleteConfirmationModal';
import CoeSelection from "./CoeSelection";

const FolderAccordianList = ({
    accordions,
    handleCreateFolder,
    handleDeleteClick,
    setAccordions,
    editedFolder,
    setEditedFolder,
    isOpen,
    setIsOpen,
    buttonValue,
    selectedRowData,
    resourceLoading,
    moreData,
    hasMoreData,
    scroll,
    setResetPage,
    setFolderDeleted,
    tabValue,
    handleCloseDeleteModal,
    setDelFolder,
    setSelectedCoeId,
    setOpenModal,
    selectedCoe,
    setSelectedCoe
}) => {
    const history = useHistory();
    const inputRef = useRef(null);
    // const [editingFolderId, setEditingFolderId] = useState(null);
    const { canRead, canDelete, canWrite, canUpdate } = JSON.parse(localStorage.getItem(USER_DETAIL))?.userPermissions;
    const role = JSON.parse(localStorage.getItem("USER_DETAIL"))?.userRole;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target) &&
                !event.target.closest('.tick-button') &&
                !event.target.closest('.cross-button')
            ) {
                // if (editingFolderId !== null) {
                //     setAccordions(
                //         accordions.map((folder) => ({
                //             ...folder,
                //             isEditing: folder.id === editingFolderId ? false : folder.isEditing,
                //         }))
                //     );
                //     setEditedFolderName('');
                //     setEditingFolderId(null);
                // }
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);
        return () => document.removeEventListener("pointerdown", handleClickOutside);
    }, [accordions, setAccordions, setEditedFolder]);

    const handleEditClick = (folderData) => {
        setEditedFolder({...folderData});
        setOpenModal(true);
    };

    const deleteFolder = () => {
        setFolderDeleted(true)
        setResetPage(true);
    }

    const documentListRedirection = (folderId, folder) => {
        const folderData = { ...folder }
        history.push('/document_list', { folderData: folderData, tabValue });
        localStorage.setItem("folderId", folderId);
    }

    const getTimeAgo = (dateString) => {
        const inputDate = new Date(dateString);
        const now = new Date();

        const diffInMs = now - inputDate;
        const diffInSeconds = Math.floor(diffInMs / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInMonths = Math.floor(diffInDays / 30);
        const diffInYears = Math.floor(diffInMonths / 12);

        if (diffInYears > 0) {
            return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
        } else if (diffInMonths > 0) {
            return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
        } else if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
        } else {
            return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
        }
    }

    return (
        <>
            {isOpen && (
                <DeleteConfirmationModal
                    openDeleteModal={isOpen}
                    handleCloseDeleteModal={handleCloseDeleteModal}
                    title='Delete Resource'
                    delBody='this resource'
                    setDelState={(val) =>
                        setDelFolder(true)
                    }
                    className={'delQBank'}
                />
            )}
            {resourceLoading ? <div className="loader-container"><Loading variant="dark" /></div> :
                <div className="folder-list">
                    <div className="search-input d-flex justify-content-between">
                        {canWrite && <Button onClick={handleCreateFolder}>Create Folder</Button>}
                        {role != "AGENT" && <CoeSelection selectedCoe={selectedCoe} setSelectedCoe={setSelectedCoe} setSelectedCoeId={setSelectedCoeId}/>}
                    </div>
                    <InfiniteScroll
                        dataLength={accordions.length}
                        next={moreData}
                        hasMore={hasMoreData}
                        loader={<div className="search-more" style={{ marginTop: '10px' }}>
                            {scroll && <BubbleLoader />}
                        </div>}
                    >
                        <div className="container" style={{ marginTop: '20px' }}>
                            <div className="resource-grid question-grid">
                                {accordions?.map((folder) => (
                                    <div className="resource-child question-child" key={folder.id}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="question-content" onClick={() => documentListRedirection(folder?.id, folder)}>
                                            <FolderOpenIcon sx={{ color: '#0b6bcb', fontSize: '60px' }} />
                                                <>
                                                    <Typography>{folder.name}</Typography>
                                                    <Typography>{`${folder.lastModified != null ? `Updated ${getTimeAgo(folder?.lastModified)}` : ''}`}</Typography>
                                                </>
                                        </div>
                                            <div className="resource-child-edit-div icon-container">
                                                {canUpdate && <Tooltip title="Edit Folder Name">
                                                    <IconButton onClick={() => handleEditClick(folder)}>
                                                        <EditIcon sx={{ color: '#0B6BCB' }} />
                                                    </IconButton>
                                                </Tooltip>}
                                                {canDelete && <Tooltip title="Delete Folder">
                                                    <IconButton onClick={(e) => handleDeleteClick(folder?.id, e, folder, 'DELETE')}>
                                                        <DeleteIcon sx={{ color: '#0B6BCB' }} />
                                                    </IconButton>
                                                </Tooltip>}
                                            </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            }
        </>
    );
};

export default FolderAccordianList;
